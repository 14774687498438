import axios    from 'axios';
// import qs from 'qs';
import config   from '@/config';
import store    from '@/store';

// 创建axios实例
const service = axios.create({
    baseURL: config.baseUrl,    // api的baseUrl
    withCredentials: false,
    timeout: 15000              // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
    config => {
        config.headers.address = store.state.app.wallet.address;
        config.headers.token = store.state.app.token;
        config.headers.language = localStorage.getItem('language') || 'en-US';

        const xAuthToken = localStorage.getItem('xAuthToken');
        if(xAuthToken){
            config.headers['x-auth-token'] = xAuthToken;
        }
        return config;
    }, error => {
        Promise.reject(error);
    }
);

// respone拦截器
service.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        // if (err.response && err.response.status) {
        //     // 错误请求的状态码
        //     let status = err.response.status
        //     if (status === 400) {
        //         Message.error('参数错误')
        //     }
        //     if (status === 401) {
        //         Message.error('登录过期,请重新登录')
        //     }
        //     if (status === 403) {
        //         Message.error('没有权限')
        //     }
        //     if (status === 404) {
        //         Message.error('接口路径错误')
        //     }
        //     if (status === 500) {
        //         Message.error('服务器出错')
        //     }
        //     if (status === 503) {
        //         Message.error('服务器在维护')
        //     }
        // }
        return Promise.reject(error);
    }
);
export default service;
