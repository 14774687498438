const lang = {
  copySuccess: 'Copy succeeded',
  operationTooFast: 'Operation too fast!',
  repeatSubmission: 'Repeat submission!',
  loading: 'Loading...',
  operationSucceeded: 'Operation succeeded!',
  operationFailed: 'Operation failed!',
  underConstruction: 'Under construction...',
  domain: 'MEKE',
  locale: 'en',
  language: 'English',
  contract: 'Contract',
  direction: 'Side',
  perpetuals: 'Perpetuals',
  margin: 'Margin',
  marginRate: 'Margin Rate',
  positionPrice: 'Position Price',
  signPrice: 'Sign Price',
  strongPrice: 'Liquidation Price',
  fundRate: 'Fund Rate',
  serviceCharge: 'serviceCharge',
  profitLoss: 'Profit/Loss',
  operation: 'Operation',
  portfolio: 'Portfolio',
  connectWallet: 'Connect Wallet',
  trade: 'Trade',
  markets: 'All markets',
  indexPrice: 'Index Price',
  oraclePrice: 'Oracle Price',
  change24h: '24h Change',
  openInterest: 'Open Interest',
  funding1h: '1h Funding',
  funding8h: '8h Funding',
  volume24h: '24h Volume',
  trade24h: '24h Trade',
  amount24h: '24h Amount',
  nextFunding: 'Next Funding',
  connectWalletIntroduce: 'Connect your wallet to deposit funds & start trading.',
  market: 'Market',
  limit: 'Limit',
  stop: 'Stop',
  stopLimit: 'Stop Limit',
  trailingStop: 'Trailing Stop',
  sell: 'Sell',
  buy: 'Buy',
  _sell: 'Sell',
  _buy: 'Buy',
  amount: 'Amount',
  orderAmount: 'Order Amount',
  amountIntroduce:
    'Amount of ETH to buy or sell. This is the amount your position will increase or decrease by when the order is filled, not your resulting position amount.',
  or: 'or',
  leverage: 'Leverage',
  upto25x: 'Up to 25×',
  short: 'Short',
  long: 'Long',
  expectedPrice: 'Expected Price',
  expectedPriceIntroduce:
    'Expected price of execution for your order. This price is only an estimate as the orderbook may change before your order is processed by the matching engine.',
  priceImpact: 'Price Impact',
  priceImpactTitle: 'Price Impact',
  priceImpactIntroduce:
    'The difference between the expected execution price for your order and the best order on the bid or ask side. This will increase for larger trade sizes.',
  fee: 'Fee',
  taker: 'Taker',
  feeIntroduce: 'Fees on dYdX are charged based on liquidity type. Maker orders carry a smaller fee than taker orders.',
  total: 'Total',
  placeMarketOrder: 'Place Market Order',
  placeLimitOrder: 'Place Limit Order',
  placeStopOrder: 'Place Stop Order',
  limitPrice: 'Limit Price',
  limitPriceIntroduce:
    'This order can only be filled at the specified limit price or better. If your order crosses at the time of placement, your order will fill any crossing orders at the most favorable price.',
  stopPrice: 'Stop Price',
  stopPriceTitle: 'Stop Price',
  stopPriceIntroduce:
    'When the index price of this market crosses your stop price, your stop order will either convert to a limit order (stop limit orders) or execute as a market order (trailing stop orders).',
  trailingPercent: 'Trailing Percent',
  trailingPercentIntroduce:
    'Trailing percent sets the percent your stop price will “trail” the index price. Whenever the index price moves in favor of your position, the stop price for your order will update according to your specified trailing percent.',
  feePercent: 'Fee Percent',

  book: 'Book',
  trades: 'Trades',
  size: 'Size',
  price: 'Price',
  mine: 'Mine',
  spread: 'Spread',
  time: 'Time',

  depth: 'Depth',
  funding: 'Funding',
  detail: 'Detail',

  marketName: 'Market Name',
  tickSize: 'Tick Size',
  tickIntroduce: 'Tick size is the minimum price movement on this market.',
  stepSize: 'Step Size',
  stepSizeIntroduce: 'Step size is the smallest factor allowed for order amounts on this market.',
  minimumOrderSize: 'Minimum Order Size',
  maximumLeverage: 'Maximum Leverage',
  maximumLeverageIntroduce:
    'Maximum allowed leverage for this market. To limit risk, maximum leverage decreases linearly with position size after a certain threshold.',
  MMfraction: 'Maintenance Margin Fraction',
  MMfractionIntroduce:
    'Margin fraction is calculated as your position notional value divided by equity. If your margin fraction exceeds the maintenance margin fraction, your position will be automatically closed (liquidated) and a liquidation fee of at least 1% will be assessed (may be higher depending on liquidity at the time of liquidation).',
  IMfraction: 'Initial Margin Fraction',
  IMfractionIntroduce:
    'Margin fraction is calculated as your position notional value divided by equity. If your margin fraction exceeds the initial margin fraction, you will no longer be allowed to increase your position. To limit risk, the initial margin fraction increases linearly with position size after a certain threshold.',
  IIMfraction: 'Incremental Initial Margin Fraction',
  IPsize: 'Incremental Position Size',
  BPsize: 'Baseline Position Size',
  MPsize: 'Maximum Position Size',

  position: 'Position',
  position2: 'Position',
  orders: 'Orders',
  fills: 'Fills',

  none: 'None',
  leverageIntroduce:
    'Leverage changes the multiplier on your gains or losses. Increasing your leverage increases how much you would gain / lose on a trade with the same price movement.',
  strongFlatPrice: 'Strong flat price',
  SFPintroduce:
    'If the oracle price of ETH drops below the liquidation price, your position will be liquidated. Upon liquidation, your position will be automatically closed and a liquidation fee of at least 1% will be assessed (may be higher depending on liquidity at the time of liquidation).',
  UPAloss: 'Unrealized P&L',
  UPAlossTitle: 'Unrealized Profit / Loss',
  UPAlossIntroduce:
    'Total profit / loss if you were to fully close the remainder of your current position at the current index price. Resets if you close your position or change position sides (long to short, or vice versa).',
  RPAloss: 'Realized P&L',
  RPAlossTitle: 'Realized Profit / Loss',
  RPAlossIntroduce:
    'Total realized profit / loss for this position through partial closes, fees and funding. Resets if you fully close your position or change position sides (long to short, or vice versa).',
  openPrice: 'Open Price',
  markPrice: 'Mark price',
  available: 'Available',

  //table
  youNoHave: 'You have no ',
  fullStop: '.',
  systemStatus: 'Status',
  side: 'Side',
  amountFilled: 'Matched/Amount',
  goodTil: 'Good Til',
  goodTilIntroduce: 'Time until your order expires.',

  type: 'Type',
  totalFee: 'TotalFee',
  liquidity: 'Liquidity',
  liquidityTitle: '流动性',
  liquidityIntroduce:
    'Transactions that obtain liquidity from the order book, such as market or cross limit orders, are executed as a bill order. All price limit orders that increase the liquidity of order book and are completely closed by the other party will be closed as pending orders.',

  views: 'Views',
  hide: 'Hide',
  overview: 'Overview',
  positions: 'Positions',
  history: 'Fills',

  buyPower: 'Buying Power',
  pastWeek: 'Past Week',
  value: 'Value',
  usage: 'Usage',
  freeCollateral: 'Free Collateral',
  LiqPriceOracle: 'Liq.Price/Oracle',
  LiqPriceOracleTitle: 'Liquidation Price',
  UsdAmount: 'Amount',
  LiqPriceOracleIntroduce:
    'If the oracle price of the position market crosses the liquidation price, your position will be liquidated. Upon liquidation, your position will be automatically closed and a liquidation fee of at least 1% will be assessed (may be higher depending on liquidity at the time of liquidation).',
  openPositions: 'Open Positions',
  averageOpenClose: 'Average Open Close',

  //fees
  makerFee: 'Maker Fee',
  takerFee: 'taker Fee',
  maker: 'Maker',
  transfers: 'Transfers',
  amountPrice: 'Amount/Price',
  fundingRate: 'Funding Rate',
  action: 'Action',
  amountFee: 'Amount/Fee',
  transaction: 'Transaction',
  connectYourWallet: 'Connect your wallet',
  aboutWallets: 'About wallets',
  skipForNow: 'Skip for now',
  copyAddr: 'Copy Address',
  exitConnect: 'Exit Connect',
  totalSize: 'Total Size',
  totalCost: 'Total Cost',
  advanced: 'Advanced',
  timeInForce: 'Time In Force',
  minute: 'Mins',
  hour: 'Hours',
  day: 'Days',
  week: 'Weeks',
  execution: 'Execution',
  postOnly: 'Post Only',
  fillOrKill: 'Fill Or Kill',
  postOnlyDetail:
    'Orders with post-only enabled can only be placed as maker orders. If the order crosses other orders at the time of placement, it will be automatically cancelled.',
  fillOrKillDetail:
    'Fill or kill orders must be completely filled upon execution or they will be automatically canceled.',
  goodTilTime: 'Good Til Time',
  immediateOrCancel: 'Immediate Or Cancel',
  assets: 'Assets',
  warnTips: 'This transaction will invalidate your position leverage.',
  warnTips2: 'There is not enough liquidity to complete this market order. Please try a smaller order amount.',
  lowerPriceWarn: 'The order price can‘t be lower than ',
  highPriceWarn: 'The order price can‘t be higher than ',
  highLeverageWarn: 'The order leverage can‘t be higher than ',
  amounExceedWarn: 'The amount of ETH can‘t be greater than ',

  account: 'Account',
  withdraw: 'Withdraw',
  deposit: 'Deposit',
  marginBalance: 'Margin Balance',
  freeMargin: 'Free Margin',
  marginUtilization: 'Margin Utilization',
  open: 'Open',
  fold: 'Fold',
  cannel: 'Cannel',
  cancelOrderConfirm: 'Are you sure to cancel this order?',
  bill: 'Bill',
  closePosition: 'Close Position',
  share: 'Share',
  neverClose: 'Never Close',
  payment: 'Payment',
  about: 'About',
  company: 'Company',
  product: 'Product',
  leveragedTrading: 'Leveraged Trading',
  developer: 'Developer',
  toTrade: 'To Trade',
  enable: 'Enable',
  approveUSDC: 'The USDT must be authorized for the first use. This operation only needs to be performed once.',
  confirm: 'Confirm',
  cancel: 'Cancel',
  notAvailableMargin: 'Insufficient available margin!',
  confirmWithdraw: 'Confirm Withdraw',
  confirmDeposit: 'Confirm Deposit',
  withdrawSuccess: 'Withdraw Success!',
  withdrawalFailed: 'Withdrawal Failed!',
  depositSuccess: 'Deposit Success!',
  depositFailed: 'Deposit Failed!',
  promotionSharing: 'Promotion Sharing',
  cctivationSucceeded: 'Activation Succeeded',
  activateVIP: 'Activate VIP',
  promotionRewardDesc: 'Promote and invite friends to get discounts',
  promotionLink: 'Promotion Link',
  promotionPreference: 'Promotion Preference',
  promotionCodeDesc: 'Promotion code of the invitee',
  copyLink: 'Copy Link',
  submit: 'Submit',
  discount0_0:
    '1. Obtain USDT ≥ 100,000 for the test, each pusher will be rewarded with an additional 2% of MEKE airdropped by the pushed person, ',
  discount0_em: 'and can directly withdraw cash for transactions;',
  discount0_1:
    'and when the pushed person obtains USDT ≥ 100,000 for the test, an additional reward will be given to each person according to the number of successful referees The recommender airdrops 1‰ of one MEKE.',
  discountOne:
    '2. The successful promotion will enjoy 30% of the commission fee of each transaction of the invitee as a rebate.',
  discountTwo:
    '3. The transaction fee of the inviter registered through the invitation link will be reduced to 60% of the original.',
  decentralization: 'Decentralization',
  tradingPerpetualDetail:
    'Start Trading Perpetual Contracts at Meke, low cost, strong liquidity, up to 25 times leverage.',
  deposit10Start: 'Deposit $10 to start.',
  nowOnline: 'Now Online',
  startTradingPerpetualContract: 'Start Trading Perpetual Contract',
  newMarketIntroduce: 'We will launch a new perpetual contract market in 2021.',
  newMarketIntroduce2: 'We will launch a faster and better sustainable contract market in the future',
  newMarketIntroduce2_0: 'We will launch a',
  newMarketIntroduce2_1: 'faster and better',
  newMarketIntroduce2_2: 'sustainable contract market in the future',
  callAllUsers: 'Call All Users',
  waitingOverDetail: 'Waiting is over, layer 2 has come.',
  waitingOverDetail2: 'Layer 2 has come',
  maxExchangeDetail: 'We have established the fastest and most powerful decentralized exchange in history.',
  maxExchangeDetail2: 'We are building the fastest and most powerful decentralized exchange in history',
  maxExchangeDetail2_0: 'We are building the',
  maxExchangeDetail2_1: 'fastest and most powerful',
  maxExchangeDetail2_2: 'decentralized exchange',
  web3Error: 'Please open it in a Web3 enabled browser',
  nodeError: 'Node connection failed. Try again after the network is unobstructed',

  /*首页底部 */
  Blog: 'Blog',
  Stay_up_to_date: 'Stay up to date',
  frequently_asked_questions: 'Frequently asked questions',
  course: 'Course',
  Watch_others_use_meke: 'Watch others use meke',
  Help_center: 'Help center',
  mekeTips: 'Tips and techniques for using meke',
  Terms_of_use: 'Terms of use',
  Platform_usage_rules: 'Platform usage rules',
  Privacy_policy: 'Privacy policy',
  Our_data_policy: 'Our data policy',
  Promotional_Terms: 'Promotional Terms',
  Our_terms_for_promotions: 'Our terms for promotions',
  mission: 'Mission',
  what_building: 'What are we building? Why?',
  Join_us: 'Join us',
  We_are_actively_recruiting: 'We are actively recruiting!',
  brand: 'Brand',
  Download_logos_and_assets: 'Download logos and assets',
  Perpetual_contract: 'Perpetual contract',
  layer2_supported: 'Layer 2 instant trading, supported by meke',
  Leveraged_Trading: 'Leveraged Trading',
  eth_main_network: 'Spot, leverage and contract transactions on Ethereum main network',
  document: 'Document',
  Trading_using_our_API: 'Trading using our API',
  our_developer_etc: 'View our smart contracts, developer tools, etc',
  status: 'Status',
  Monitor_exchange_status: 'Monitor exchange status',
  Grasp_the_pulse: 'Grasp the pulse',
  Subscribe_to_tutorials: 'Subscribe to tutorials',
  Post_on_our_forum: 'Post on our forum',
  View_our_vacancies: 'View our vacancies',
  WhitePaper: 'White Paper',
  WhitePaperDesc: 'White Paper',

  /**首页中部 */
  itemTitle1: 'Safe and efficient',
  itemTitle2: 'Transaction on chain',
  itemTitle3: 'Open and transparent',
  itemTitle4: 'User friendly',
  itemTitle5: 'Asset security is guaranteed by smart contracts',

  WelcomeToJoinMekeClub_0: 'Welcome to join the',
  WelcomeToJoinMekeClub_1: 'MEKE',
  WelcomeToJoinMekeClub_2: 'club',
  MekeDesc:
    'MEKE is a decentralized cryptocurrency derivatives trading platform deployed on Binance Smart Chain, which is safe, efficient and transparent. Restart your perpetual futures trading career.',
  mekeIntroduce1: 'Low cost, no gas cost',
  mekeIntroducecontent1: 'Ultra low cost. Once you deposit layer 2, you no longer have to pay for each transaction.',
  mekeIntroduce2: 'Ultrafast',
  mekeIntroducecontent2:
    'Lightning speed. The transaction can be executed immediately and confirmed on the blockchain within a few seconds.',
  mekeIntroduce3: 'Quick withdrawal',
  mekeIntroducecontent3: 'Quick withdrawal. You can withdraw money from layer 2 without waiting.',
  mekeIntroduce4: 'Mobile friendly',
  mekeIntroducecontent4: 'Support mobile terminal. We redesigned the exchange so you can now use it anytime, anywhere.',
  mekeIntroduce5: 'Security and privacy',
  mekeIntroducecontent5:
    "Security and privacy. Meke's layer 2 improves security and privacy through zero knowledge proof.",
  mekeIntroduce6: 'Cross Margin',
  mekeIntroducecontent6: 'Cross margin. An account can access positions of different transaction pairs.',
  startTrading: 'Start Trading',
  startTrading2: 'Start Trading',
  StartTrading2Desc: 'Experience the same smooth trading experience as centralized perpetual contract trading.',
  toggleChainWarn: 'Set your wallet network to ',
  switchingChain: 'Switching Chain',
  now8FundingRate: 'Current 8h Rate',
  closingPosition: 'Waiting...',
  placingMarketOrder: 'Placing market order...',
  placingLimitOrder: 'Placing limit order...',
  processing: 'Processing...',
  successfulChaining: 'Success',
  uplinkFailure: 'Fail',
  insufficientLiquidity: 'Insufficient liquidity!',
  notice: 'Notice',
  noNewNotice: 'No new notifications.',
  feedback: 'Feedback',
  feedDetail: 'Tell us how to build a better trading experience.',
  send: 'Send',
  socketDisconnect: 'Connection disconnected! Please reconnect to continue using.',
  gasFee: 'Gas Fee',
  networkNormal: 'The network has returned to normal!',
  networkUnavailable: 'Network connection unavailable!',
  onConnection: 'On connection...',
  retry: 'retry',
  orderNotMatched: 'Order not matched!',
  rateDiscount: 'Rate Discount',
  grade: 'Grade',
  currencyHolding: 'Currency Holding',
  discount: 'Discount',
  costStructure: 'Cost Structure',
  trade30: 'Transaction Volume (30 Days)',
  mining: 'Mining',
  withdrawal: 'Withdrawal',
  stableConnection: 'Stable connection',
  instableConnection: 'Instable connection',
  youNotTest: 'You are not qualified for internal test!',
  cumulativeIncome: 'Get MEKE at most',
  liquidityMiningIncome: 'Liquidity Mining Income',
  tradingMiningIncome: 'Trading Mining Income',
  miningWithdrawal: 'Mining Withdrawal',
  walletBalance: 'Wallet Balance',
  availableWalletBalance: 'Available Wallet Balance',
  walletBalanceRate: 'Wallet Balance Utilization',
  Insufficient_available_wallet_balance: 'Insufficient Available Wallet Balance!',
  Obtained: 'Obtained',
  Not_obtained: 'Not Obtained',
  Not_released: 'Not Released',
  Withdrawable_cash: 'Withdrawable Cash',
  all: 'All',
  enter_withdrawal: 'Please enter the withdrawal amount.',
  enter_deposit_account: 'Please select a donation usdt deposit account.',
  balance: 'Balance',
  explain: 'Explain',
  explainDesc1: '1. For every 1meke withdrawn, you need to donate 0.5 usdt.',
  explainDesc2: '2. You need to pay 10 usdt transaction fee for each withdrawal.',
  donation: 'Donation',
  totalTransactions: 'Total Transaction U Quantity',
  AirdropNumber: 'MEKE Obtained',
  get_test: 'Obtain MEKE test materials',
  get_test_desc:
    'MEKE is a USDT-based decentralized derivatives trading platform. To participate in the public beta, you need to obtain USDT for testing.',
  GetTestMaterials: 'Get test materials',
  test_desc1: 'MEKE airdrop can be obtained with test U transaction.',
  test_desc2: 'Each transaction of U will get a corresponding number of MEKE.',
  test_desc3: 'The maximum number of MEKEs available is equal to the number of test U initially obtained.',
  audit: 'Audit',
  audit_desc: 'Safety is our top priority',
  discord_desc: 'Chat with other traders',
  getMeke: 'Get test chain BNB',
  getBNB: 'Get test chain BNB',
  publicTestUrl: 'Public beta USDT contract address：',
  InvitedFriends: 'Invited Friends',
  day: 'D',
  hour: 'H',
  minute: 'M',
  second: 'S',
  socketRetry: 'Terrible network, Retrying...',

  HowToGetAirdrop_0: 'How to get the',
  HowToGetAirdrop_1: 'MEKE airdrop',
  HowToGetAirdrop_2: '?',

  GetItNow: 'Get it now',
  More: 'More',
  MEKENews: 'MEKE News',

  CountdownStart: ' will come soon',
  ActivityRunning: ' is in progress',
  CountdownEnd: ' is ending',
  ActivityFinished: ' has ended',

  airdropDetail: 'Participate in the public beta and earn airdrops',
  airdrop: 'Airdrop',
  title: 'Title',
  releaseRule: 'Release Rule',
  totalReleased: 'Total Released',
  totalAmount: 'Total Amount',
  collectedAmount: 'Collected Amount',
  collectedPercent: 'Collected %',
  releasedPercent: 'Released %',
  collect: 'Collect',
  MyAirdrops: 'My Airdrops',
  collected: 'Collected',
  SuccessToCollect: 'Successful to collect',
  noAirdrops: 'No Airdrops',

  Airdrop1: 'Airdrop 1',
  Airdrop2: 'Airdrop 2',
  CanGetUpToMEKE: 'Can get up to MEKE',
  TradedUSDT: 'Traded USDT',
  EarnedMEKE: 'Earned MEKE',

  TradingPoints: 'Trading Points',
  InvitationPoints: 'Invitation Points',
  ReferralPoints: 'Referral Points',
  ReferralAdditionalRewards: 'Additional Rewards',
  Goto: 'Go to',
  Swap: 'Swap',

  TotalAirdrop: 'Total Amount of Aidrops',
  airdrop2_desc1:
    'The airdrop points are calculated on the basis of completing the airdrop 1, and each additional point will add an additional 1% of the amount acquired by the airdrop 1.',
  airdrop2_desc2: 'Participation conditions: acquired test USDT ≥ 100,000 USDT',

  opBNBBridge: 'opBNB Bridge',
  opBNBBridge_title: 'Transfer BNB to opBNB Testnet',
  News: 'News',
  PublicBetaTutorials: 'Public Beta Tutorials',
  GetOpbnbGasFee: 'Get opBNB gas fee',
  GetOpbnbGasFeeDesc:
    'MEKE is currently running on the opBNB test network, and transactions on MEKE require BNB in ​​opBNB as gas fees.',

  OpbnbGasFeeCollected: 'opBNB Gas Fee Collected',
  Account: 'Account',
  GetOpbnbTip: 'Connect wallet to get opBNB gas fee',
  AlreadyCollectedTip: 'current address already collected',
  GetOpbnbNeedUsdtTip: 'Need BSC USDT ≥ 0.1, only used to block robots, no payment required',

  Total: 'Total',
  Received: 'Received',
  Remain: 'Available',
  Released: 'Released',
  InvalidSignature: 'Invalid Signature',
  AirdropInsufficient: 'Received All',
  JoinMekeAirdrop: 'Join the MEKE airdrop',
  WMEKEAddress: 'WMEKE contract address',
  WithdrawalTutorial: 'Withdrawal Tutorial',
  Liquidation: 'Liquidation',
  LiquidateNotice: 'Liquidatation Notice',
  LiquidatedTip:
    'Your margin account balance is lower than the minimum required margin, and the ETH/USDT position has been liquidated',

  Profit: 'Profit',
  Loss: 'Loss',
  stopProfitSetting: 'Stop Profit',
  stopLossSetting: 'Stop Loss',
  LimitOrder: 'Limit Order',
  MarketOrder: 'Market Order',
  CloseOrderType: 'Order Type',
  Cost: 'Cost',
  CostPrice: 'Entry Price',
  StopProfitPrice: 'Stop Profit Price',
  StopLossPrice: 'Stop Loss Price',
  Change: 'Change',
  Size: 'Size',
  Amount: 'Amount',
  Side: 'Side',
  LONG: 'LONG',
  SHORT: 'SHORT',
  EMPTY: 'EMPTY',
  FLAT: 'FLAT',

  MyShare: 'My share',
  MiningHistory: 'Mining History',
  CurrentTermTradingFee: 'Trading Fee in this period',
  TradingFee: 'Trading Fee',
  LiquidityScore: 'Liquidity Score',
  EstimatedReward: 'Estimated Rewards(MEKE) in this period',
  TermReward: 'Rewards(MEKE)',
  TotalReward: 'Total Rewards(MEKE)',
  CurrentProgress: 'Progress',
  TradingMiningDesc:
    'Trading Rewords: Within a period of time (28 days), rewards (MEKE) will be issued based on the proportion of the handling fee paid by the user to the total handling of the current period.',
  LiquidityMiningDesc:
    'Within a period of time (28 days), points are calculated based on the effective market depth provided by the user, and rewards (MEKE) are issued based on the proportion of user points to the total points of the current period.',

  Earn: 'Earn',
  Staking: 'Staking',
  MyEarns: 'My Earns',
  ReferralBonus: 'Referral Bonus',
  StakingMeke: 'Staking MEKE',
  StakingMekeDesc: 'Generate additional MEKE interest and transaction fee dividends by pledging MEKE tokens',

  days: ' days',
  APR: 'APR',
  StakeNow: 'Stake now',
  Period: 'Period',
  Balance: 'Balance',
  Interest: 'Interest',
  CompoundInterest: 'Compound Interest',

  Special: 'Best',
  MekeSpecialPackage: 'Meke Special Package',
  MekeSpecialPackageDesc:
    'In addition to airdrop staking, users who pledge MEKE need to purchase MEKE before staking. Users can purchase MEKE on uniswap on opBNB or at a discount on the official website.',
  BuyItNow: 'Buy it now',
  BuyAndStakeDesc1: 'After purchase, it will be automatically transferred to the ',
  BuyAndStakeDesc2: ' days staking pool',

  MekeStakingRule: 'MEKE staking income calculation rules',
  MekeStakingRuleDesc1:
    'Create four staking income methods with cycles of 5 days, 30 days, 90 days, and 180 days respectively.',
  MekeStakingRuleDesc2: 'The interest rate is calculated based on MEKE.',
  MekeStakingRuleDesc3:
    'The APR for different periods is different. The APR for a 180-day staking period is 50%, 90 days is 35%, 30 days is 20%, and 5 days is 5%.',
  MekeStakingRuleDesc4:
    'Note that when a user stakes MEKE, he or she will immediately generate income. If the user re-invests automatically, the income will be compounded in seconds. The APR is calculated compounded per secondt',
  MekeStakingRuleDesc5:
    'Take a 180-day staking cycle and an APR of 50% as an example. The 50% is the annualized interest rate obtained by calculating the total interest for one year through the compound interest generated by re-investment every second, and then dividing the total interest by the initial principal.',
  MekeStakingRuleDesc6:
    'When withdraw staking interest, 5% of the interest withdrawn will be destroyed. For example, to withdraw 100 MEKE interest, 95 MEKE is actually received and 5 MEKE is destroyed.',

  StakingReleaseRule: 'Staking release rules',
  StakingReleaseRuleDesc1:
    'After staking, it will be released every second on average according to the number of staking cycles. If the staking is for 5 days, after the 5 days are over, the principal and interest will be released on average every second according to the 5-day cycle. MEKE after release can be withdraw',
  StakingReleaseRuleDesc2:
    'During the release period, as long as the released MEKE is not withdrawn, interest can still accrue. Interest can still be reinvested. After the release is over, interest will no longer accrue.',

  FeeDividendRule: 'Transaction fee dividend rules',
  FeeDividendRuleDesc1:
    'In addition to staking MEKE to earn interest, staking MEKE can also be divided into 60% of the entire transaction fee.',
  FeeDividendRuleDesc2:
    'The specific allocation method is one for each transaction, and is evenly distributed according to the current number of staking MEKE. The fee is USDT, and users need to spend GAS to withdraw.',
  FeeDividendRuleDesc3:
    'In addition to the allocated transaction fee, the project party manually fills in a certain percentage to the user every 10 days. For example, if a user receives 20U of transaction fees in 10 days, the project owner can increase the amount by 20%, that is, increase the amount by 4U of transaction fees. This number is a manual operation of the project party. The specific time and quantity are subject to the project party.',

  StakingEarns: 'Staking Earns',
  Principal: 'Principal',
  TotalReleased: 'Total Released',
  MyShares: 'My Shares',
  TotalInterest: 'Total Interest',
  WithdrawEarns: 'Withdraw',
  Available: 'Available',
  Withdrawn: 'Withdrawn',
  TotalTransactionFee: 'Total Transaction Fees',

  StakingRecords: 'Staking Records',

  C_StartTime: 'Start Time(UTC)',
  C_Period: 'Period',
  C_APR: 'APR(%)',
  C_Principal: 'Principal(MEKE)',
  C_Interest: 'Interest(MEKE)',
  C_Released: 'Released(%)',
  C_Available: 'Available(MEKE)',

  TransactionFeeExtraRewards: 'Transaction fee extra rewards',
  D_Time: 'Time(UTC)',
  D_Amount: 'Amount(USDT)',

  InviteFriends: 'Invite Friends',
  InviteFriendsDesc: 'Invite friends to enjoy additional rewards',
  Copy: 'Copy',

  InvitationRule: 'Invitation Rules',
  InvitationRuleDesc1:
    "The invitee must register through someone else's invitation link and must meet VIP activation requirements before the invitation can be successful.",
  InvitationRuleDesc2:
    "Every time A invites a user B, an additional 10% of the interest generated by A's invitee B's pledge of MEKE will be rewarded. That is, if the invitee generates 100MEKE interest, the inviter A will receive an additional 10% reward, which is 10MEKE.",
  InvitationRuleDesc3:
    'When withdraw referral bonus, 5% of the withdrawn bonus will be destroyed. For example, withdraw 100 MEKE, actually receive 95 MEKE, and destroy 5 MEKE.',

  TotalReferralBonus: 'Total Referral Bonus',
  WithdrawBonus: 'Withdraw',

  ReferralBonus: 'Referral Bonus',
  E_Addr: 'Address',
  E_Bonus: 'Referral Bonus',

  F_ActivationTime: 'ActivationTime(UTC)'
}
export default lang;
