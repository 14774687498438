<template>
<div class="body" style="position:relative;">
    <!-- <div class="network">网络异常</div> -->
    <div v-if="isShow || $route.name!=='trade'">
        <router-view />
    </div>
    <div v-else-if="retryMsg">
        <p class="loading-tip">{{retryMsg}}</p>
    </div>
    <div v-else>
        <p class="loading-tip">{{$t('loading')}}</p>
    </div>

    <div class="chat-btn-wrap">
        <Button v-if="!showChat" @click="showChat = true; showAirdropText=false" class="chat-btn" :class="{hasText:showAirdropText}" size="large" type="primary" shape="circle" >
            <svg style="width: 1.8em; height: 1.8em;vertical-align: middle;fill: currentColor;overflow: hidden;" 
            viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M919.192216 976.840649a42.620541 42.620541 0 0 1-21.919135-6.088649l-185.094919-110.675027A560.95827 560.95827 0 0 1 512 896.249081c-274.681081 0-498.162162-192.982486-498.162162-430.190703C13.837838 228.850162 237.318919 35.867676 512 35.867676S1010.162162 228.850162 1010.162162 466.058378c0 104.64173-42.952649 203.637622-121.66227 281.821406l70.379243 168.683243c7.195676 17.269622 2.601514 37.251459-11.374703 49.567135-8.025946 7.084973-18.127568 10.710486-28.312216 10.710487z m-203.277838-208.45319c7.610811 0 15.193946 2.048 21.919136 6.088649l91.108324 54.438054-31.494919-75.443892a43.699892 43.699892 0 0 1 11.623784-49.816216c74.170811-64.345946 115.020108-148.729081 115.020108-237.595676C924.090811 276.756757 739.217297 122.713946 512 122.713946S99.909189 276.756757 99.909189 466.058378c0 189.301622 184.873514 343.344432 412.090811 343.344433 65.785081 0 128.719568-12.647784 187.142919-37.583568 5.369081-2.297081 11.07027-3.431784 16.771459-3.431784zM260.953946 470.154378a56.32 56.32 0 0 1 56.347676-56.015567 56.347676 56.347676 0 0 1 55.794162 56.596757c0 31.135135-24.908108 56.430703-55.794162 56.569081A56.32 56.32 0 0 1 260.981622 471.316757v-1.134703z m186.478703 0c0 31.965405 25.710703 57.897514 57.399351 57.897514a57.648432 57.648432 0 0 0 57.371676-57.897514 57.648432 57.648432 0 0 0-57.371676-57.897513 57.648432 57.648432 0 0 0-57.399351 57.897513z m186.506378 0a56.32 56.32 0 0 1 56.347676-56.015567 56.347676 56.347676 0 0 1 55.794162 56.596757c0 31.135135-24.908108 56.430703-55.794162 56.569081a56.32 56.32 0 0 1-56.347676-56.015568v-1.134703z" 
                fill="currentColor" />
            </svg><span v-if="showAirdropText">{{ $t('JoinMekeAirdrop') }}</span></Button>
        <Button v-if="showChat" @click="showChat = false" class="chat-btn" type="primary" size="large" shape="circle" style="opacity:0.3">
        <svg 
        style="width: 1.8em; height: 1.8em;vertical-align: middle;fill: currentColor;overflow: hidden;"
        xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50">
            <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"
                fill="currentColor"/>
        </svg>
        </Button>
    </div>

</div>
</template>

<script>
import request from '@/utils/request';
import WalletUtil from "@/utils/walletUtil"
import ContractUtil from "@/utils/contractUtil"
import { createRelation,sendMsg, sendTradePairMsg} from "@/api/http"
import config from '@/config';
import { getUrlParam,randomStringOrTime } from "@/utils/base"
import SocketUtil from '@/socket/SocketUtil'

export default {
    data () {
        return {
            showAirdropText:false,
            showChat:false,
            isShow:false,//交易对信息收到后加载页面
            retryMsg:''
        };
    },
    components: {
        
    },
    mounted () {
        this.showAirdropText = this.fullPath === '/';
        //设置通知弹窗的配置
        this.$Notice.config({
            top: 48,
            duration: 4.5
        });
        // this.addNetworkEvent();
        //监听页面刷新释放
        window.addEventListener("beforeunload",(e)=> {
            this.isShow=false;
            this.destroyEvent();
            window.removeEventListener('beforeunload', ()=>{})
            window.removeEventListener('offline', ()=>{})
            window.removeEventListener('online', ()=>{})
        });
        this.$store.state.app.token=randomStringOrTime(32);
        setTimeout(() => {
            this.initSocket();
        }, 0);
        this.isShare();
        this.init();
    },
    methods: {
        //监听通知消息
        addNoticeMsg(){
            this.$eventBus.$on("message_msg",(res)=>{
                this.$store.commit('system/setIsUnread',true);
                let d=res.data;
                if(this.lang=='zh-CN'){     //中文部分
                switch (d.type) {
                    case 60001:     //爆仓
                        this.$Notice.error({
                            title:`爆仓`,
                            desc:`${d.abbreviation}-USD${d.side=='buy'?'多':'空'}仓已于时间${d.timeStr.substr(11,5)}被强平。持仓均价$${d.price}，数量${d.amount} ${d.abbreviation}，强平价格$${d.indexPrice}。`,
                            duration:20,
                        });				
                        break;
                    case 60002:     //撤单
                        if(d.reason==60012){        //订单过期
                            this.$Notice.error({
                                title:`撤单`,
                                desc:`${d.side=='buy'?'买入':'卖出'}${d.abbreviation}-USD于时间${d.timeStr.substr(11,5)}已被系统撤单。原因：订单已超时。下单价格$${d.price}，数量${d.amount} ${d.abbreviation}。`,
                                duration:20,
                            });
                        }else if(d.reason==60001){  //爆仓
                            this.$Notice.error({
                                title:`撤单`,
                                desc: `${d.side=='buy'?'买入':'卖出'}${d.abbreviation}-USD于时间${d.timeStr.substr(11,5)}已被系统撤单。原因：保证金不足。下单价格$${d.price}，数量${d.amount}${d.abbreviation}。`,
                                duration:20,
                            });
                        }else if(d.reason==60008){  //保证金不足
                            this.$Notice.error({
                                title:`撤单`,
                                desc: `${d.side=='buy'?'买入':'卖出'}${d.abbreviation}-USD于时间${d.timeStr.substr(11,5)}已被系统撤单。原因：保证金不足。下单价格$${d.price}，数量${d.amount}${d.abbreviation}。`,
                                duration:20,
                            });
                        }
                        break;
                    case 60003:     //上链失败
                        this.$Notice.error({
                            title:`上链失败`,
                            desc: `${d.side=='buy'?'买入':'卖出'}${d.abbreviation}-USD于${d.timeStr.substr(11,5)}上链失败。原因：保证金不足。下单价格$${d.price}，数量${d.amount}${d.abbreviation}。`,
                            duration:20,
                        });
                        break;
                    default:
                        break;
                }
                }else if(this.lang=='en-US'){   //英文部分
                switch (d.type) {
                    case 60001:     //爆仓
                        this.$Notice.error({
                            title:`Burst warehouse`,
                            desc:`${d.abbreviation}-USD ${d.side=='buy'?'long':'short'} position was liquidated at ${d.timeStr.substr(11,5)}，average price $${d.price}，amount ${d.amount} ${d.abbreviation}，liquidation price $${d.indexPrice}.`,
                            duration:20,
                        });				
                        break;
                    case 60002:     //撤单
                        if(d.reason==60012){        //订单过期
                            this.$Notice.error({
                                title:`Cancel the order`,
                                desc:`${d.abbreviation}-USD ${d.side=='buy'?'long':'short'} position was cancelled at ${d.timeStr.substr(11,5)}. Reason:order expired,price $${d.price} amount ${d.amount} ${d.abbreviation}.`,
                                duration:20,
                            });
                        }else if(d.reason==60001){  //爆仓
                            this.$Notice.error({
                                title:`Cancel the order`,
                                desc: `${d.abbreviation}-USD ${d.side=='buy'?'long':'short'} position was cancelled at ${d.timeStr.substr(11,5)}. Reason:Insufficient margin balance,price $${d.price} amount ${d.amount} ${d.abbreviation}.`,
                                duration:20,
                            });
                        }else if(d.reason==60008){  //保证金不足
                            this.$Notice.error({
                                title:`Cancel the order`,
                                desc: `${d.abbreviation}-USD ${d.side=='buy'?'long':'short'} position was cancelled at ${d.timeStr.substr(11,5)}. Reason:Insufficient margin balance,price $${d.price} amount ${d.amount} ${d.abbreviation}.`,
                                duration:20,
                            });
                        }
                        break;
                    case 60003:     //上链失败
                        this.$Notice.error({
                            title:`Uplink failure`,
                            desc: `${d.abbreviation}-USD ${d.side=='buy'?'long':'short'} position opening failed at ${d.timeStr.substr(11,5)}.Reason:Insufficient margin balance,price $${d.price} amount ${d.amount} ${d.abbreviation}.`,
                            duration:20,
                        });
                        break;
                    default:
                        break;
                }
                }
                
                // this.$Notice.success({
                //     title: `ETH-USD多仓已于时间16:00强平。持仓均价＄4300，数量1ETH，强平价格＄4400。`,
                //     duration:4.5,
                // });
            });
        },
        //监听网络状态
        addNetworkEvent(){
            window.addEventListener("online",()=>{
                this.$Notice.success({
                    title:`${this.$t('networkNormal')}`,//网络正常
                    duration:4.5,
                });
                window.removeEventListener('online', ()=>{})
            })
            window.addEventListener("offline",()=>{
                // alert('网络断开了')
                this.$Notice.error({
                    title:`${this.$t('networkUnavailable')}`,//网络异常
                    duration:4.5,
                });
                window.removeEventListener('offline', ()=>{})
            })
        },
        //释放监听器
        destroyEvent(){
            SocketUtil.close();
            this.$eventBus.$off("mekeConfig_msg");
            this.$eventBus.$off("message_msg");
        },
        //连接socket
        initSocket(){
            //地址配置，socket连接成功推送
            this.$eventBus.$on("mekeConfig_msg",(res)=>{
                let data=res.data || {};
                this.$store.commit("setCurrencyConfig",{
                    blockChainAddr:data.blockChainAddr,
                    exchange:data.exchange,
                    usdc:data.usdc,
                    contractReader:data.contractreader
                });
                this.addNoticeMsg();
                ContractUtil.init([data.blockChainAddr, ...data.rpcUrls || []], () => {
                    this.isShow = true;
                    this.retryMsg = '';
                });
            });
            
            this.$eventBus.$on("websocket_retry",({retryCount,event})=>{
                this.retryMsg=this.$t('socketRetry')+' '+retryCount;
                console.log(this.retryMsg, {type:event.type, url:(event.target||{}).url})
            })

            SocketUtil.connectSocket(()=>{
                sendMsg({cmd:'config',data:this.blockchain.perpetual});//资金费率
                sendMsg({cmd:'tradePair'});
                sendMsg({cmd:'handicapInformation',data:this.blockchain.perpetual});
            });
        },
        //是否分享
        isShare(){
            let addr=getUrlParam(document.URL,'addr');
            if(addr){
                localStorage.setItem('share_addr',addr);
                //调用分享接口，并清除url后边参数
            }
        },
        //初始化钱包地址
        init(){
            //是否存在本地缓存地址，没有缓存说明没有登录，没有登录就不用授权钱包。
            let addr=sessionStorage.getItem('user_info_addr');
            if(addr){
                WalletUtil.walletInit(()=>{
                    sendMsg({cmd:'login',data:this.$store.getters['getAddr']});//发送登录消息
                });
            }
        },
        //分享
        createRelation(){
            let address=localStorage.getItem('share_addr');
            let addr=this.$store.getters['getAddr'];
            if(!address || addr==address){
                return;
            }
            createRelation({
                inviteAddress:address,//上级id（邀请者id）
                lowAddress:addr,//当前用户id（下级id）
            }).then(res=>{
                if(res.code == 200){

                }else{
                    // this.$Message.error(this.$t('operationFailed'));
                }
            });
        },
    },
    computed:{
        //是否初始化钱包
        isInitWallet() {
            return this.$store.state.app.wallet.isInit;
        },
        //多语言切换
        lang(){
            return this.$i18n.locale;
        },
        blockchain(){
            return this.$store.state.blockchain;
        },
        fullPath() {
            return this.$route.fullPath;
        }
    },
    watch:{
        showChat(val){
            const chatWrap=document.getElementById('chatWrap');
            if(chatWrap){
                if(val){
                    chatWrap.classList.add('show');
                }else{
                    chatWrap.classList.remove('show');
                }

            }
        },
        fullPath() {
            this.showAirdropText = this.showAirdropText && this.fullPath === '/';
        },
        //是否登录钱包
        'isInitWallet':{
            handler:function(val,value){
                if(val){
                    this.createRelation();
                }else{
                }
            },
            immediate: true
        },
    },
    beforeDestroy() {
        SocketUtil.close();
        this.$eventBus.$off("mekeConfig_msg");
        this.$eventBus.$off("message_msg");
        // this.$eventBus.$off("config_msg");
    },
};
</script>
<style lang="less">
html,body{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
/* H5的时候，隐藏滚动条 */
// ::-webkit-scrollbar {
// 	display: none;  
// 	width: 0 !important;  
// 	height: 0 !important;  
// 	-webkit-appearance: none;  
// 	background: transparent;  
// }
.network{
    position: absolute;
    background: #6179fa;
    width: 100%;
    z-index: 10000;
    text-align: center;
    color: white;
    padding: 5px;
    font-size: 16px;
    top:0;
}

.loading-tip {
    display:block;
    height:90vh;
    padding-top:10vh;
    text-align:center;
}

.chat-btn-wrap {
    position:fixed;
    bottom:30vh;
    right:24px;
    z-index:20000;

    .chat-btn {
        opacity:0.6;
        width:40px;
        height:40px;
        border-radius:40px;
        padding:0;
        box-shadow: rgba(0, 77, 255, 0.5) 0px 4px 24px;
        & svg {
            width:40px;
            height:40px;
        }

        &.hasText {
            width:unset;
            padding:0 8px;
            & svg {
                margin-right:8px;
            }
        }

        &:hover {
            opacity:1;
        }
    }
}





</style>