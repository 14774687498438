const initState = {}
try {
  Object.assign(initState, JSON.parse(localStorage.getItem('appConfig') || '{}'))
} catch (err) {
  console.warn(err.message)
}

//应用数据
export default {
  state: Object.assign({
    wallet: {
      isInit: false, //是否已链接
      address: '', //以太坊钱包地址。我的地址
      chainId: '' //链id。链是否正确
    },
    token: '', //token，前端生成随机字符串
    realTakerFeeRate: '', //费率
    realMakerFeeRate: '', //费率
    takerFeeRate: '', //交易费率
    gasFee: '', //上链手续费
    walletActiveName: '' //登录的钱包名称
  },initState),
  getters: {
    //获取地址
    getAddr(state) {
      return state.wallet.address
    },
    //获取token
    getToken(state) {
      return state.token
    }
  },
  mutations: {
    setAppConfig(state, val) {
      Object.keys(val).forEach((item) => {
        state[item] = val[item]
      })
    },
    //登录
    login(state, val) {
      let { isInit, address } = val
      console.log('以太坊钱包地址：', address, (performance.now() / 1000).toFixed(3) + 's')
      state.wallet.isInit = isInit
      state.wallet.address = address
      sessionStorage.setItem('user_info_addr', address)
    },
    //退出登录
    exitLogin(state) {
      state.wallet.isInit = false
      state.wallet.address = ''
      sessionStorage.removeItem('user_info_addr')
      sessionStorage.removeItem('walletActive')
    },
    //设置链id
    setChainId(state, val) {
      let { chainId } = val
      state.wallet.chainId = chainId
    },
    //设置费率
    setFeeRate(state, val) {
      let { realTakerFeeRate, realMakerFeeRate, takerFeeRate, gasFee } = val
      state.realTakerFeeRate = realTakerFeeRate
      state.realMakerFeeRate = realMakerFeeRate
      state.takerFeeRate = takerFeeRate
      state.gasFee = gasFee
    },
    //设置交易手续费
    // setTakerFeeRate(state,val){
    //     state.takerFeeRate=val;
    // },
    //设置登录的钱包
    setWalletActiveName(state, val) {
      state.walletActiveName = val
    }
  },
  actions: {}
}
