import Vue 		from 'vue';
import Router 	from 'vue-router';
import routes 	from './routers';
import config 	from '@/config';
import store  	from '@/store';


Vue.use(Router);
const router = new Router({
    mode:'history',
    routes
});
router.beforeEach((to, from, next) => {
    next();
});
router.afterEach(to => {
    // 每次切换页面 改变标题 并回到顶部
    window.document.title = config.website.title;
    window.scrollTo(0, 0);
});
//冗余导航
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router;
