// 两个浮点数求和
function accAdd(num1,num2){
    var r1,r2,m;
    try{
        r1 = num1.toString().split('.')[1].length;
    }catch(e){
        r1 = 0;
    }
    try{
        r2=num2.toString().split(".")[1].length;
    }catch(e){
        r2=0;
    }
    m=Math.pow(10,Math.max(r1,r2));
    // return (num1*m+num2*m)/m;
    return Math.round(num1*m+num2*m)/m;
}
// 两个浮点数相减
function accSub(num1,num2){
    var r1,r2,m,n;
    try{
        r1 = num1.toString().split('.')[1].length;
    }catch(e){
        r1 = 0;
    }
    try{
        r2=num2.toString().split(".")[1].length;
    }catch(e){
        r2=0;
    }
    m=Math.pow(10,Math.max(r1,r2));
    n=(r1>=r2)?r1:r2;
    return (Math.round(num1*m-num2*m)/m).toFixed(n);
}
// 两数相乘
function accMul(num1,num2){
    var m=0,s1=num1.toString(),s2=num2.toString();
    try{m+=s1.split(".")[1].length}catch(e){};
    try{m+=s2.split(".")[1].length}catch(e){};
    return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m);
}
// 两数相除
function accDiv(num1,num2){
    var t1,t2,r1,r2;
    try{
        t1 = num1.toString().split('.')[1].length;
    }catch(e){
        t1 = 0;
    }
    try{
        t2=num2.toString().split(".")[1].length;
    }catch(e){
        t2=0;
    }
    r1=Number(num1.toString().replace(".",""));
    r2=Number(num2.toString().replace(".",""));
    return (r1/r2)*Math.pow(10,t2-t1);
}
/**
 * 判断字符是否为空
 * str：字符串
 */
function isNullorEmpty(str) {
    return (str == null || str == "" || str == undefined || str == "undefined") && (str != 0 || str != "0") ? true : false;
}
//格式化
export function toFixed(val,len=0){
    if(isNullorEmpty(val)){
        return '-';
    }else{
        // return val && val != 0 ? (Number(val)).toFixed(len) : '0';
        return val && val != 0 ? (accDiv(Math.floor(accMul(Number(val),Math.pow(10,len))),Math.pow(10,len))).toFixed(len) : '0';
    }
}

//格式化百分比，为零显示零
export function toPercent(val,len){
    if(isNullorEmpty(val)){
        return '-';
    }else{
        return val && val != 0 ? (accDiv(Math.floor(accMul(Number(val),Math.pow(10,len))),Math.pow(10,len))).toFixed(len)+'%' : '0'+'%';
    }
}

//格式化数据，自定义追尾字符串参数
//len：小数位长度,str：追尾字符串
//示例：{{openPositionContract | format(2,' ETH')}}
export function format(val,len=0,str=''){
    if(val===0){
        return 0+str;
    }else{
        return val && val != 0 ?(accDiv(Math.floor(accMul(Number(val),Math.pow(10,len))),Math.pow(10,len))).toFixed(len)+str : '-';
    }
}

//数值过千加逗号，并保留几位小数
//num：数值。计算属性不用传
//len：小数精度位
//symbol：默认格式化的符号。可以为空
export function numFormat(num,len,symbol='$') {
    if(isNullorEmpty(num)){
        return '-';
    }
    var isNegative=num>=0 ? false : true;
    if(isNegative){     //是否负数
        num=Math.abs(Number(num));
    }

    // var c=num && num != 0 ? Number(num).toFixed(len).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : '0';
    // var c=num && num != 0 ? (Math.floor(Number(num) * Math.pow(10,len)) / Math.pow(10,len)).toFixed(len).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : '0';
    var c=num && num != 0 ? toThousands((accDiv(Math.floor(accMul(Number(num),Math.pow(10,len))) , Math.pow(10,len))).toFixed(len)) : '0';

    c=symbol+c;
    if(isNegative){     //是否负数
        c='-'+c;
    }
    return c;
}

//过千加逗号
function toThousands(num) {
    var result = '', counter = 0;
    var dot = String(num).indexOf(".");
    if(dot != -1){
         // alert("有小数点");
        // 获取小数点后面的数字(indexOf和substring都不支持数字，所以要先转字符串才可以用)
        var dotCnt = String(num).substring(dot+1,num.length);
 
        // 获取小数点前面的数字
        num = String(num).split('.')[0]
        num = (num || 0).toString();
        for (var i = num.length - 1; i >= 0; i--) {
            counter++;
            result = num.charAt(i) + result;
            if (!(counter % 3) && i != 0) { result = ',' + result; }
        }
        result = result + '.' + dotCnt;
        return result;
        
    }else{
        // alert("没有小数点");
        return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    }
}

//数字转为阿拉伯数字
export function intToRoman(num) {
    let map = {
        1: 'I',
        5: 'V',
        10: 'X',
        50: 'L',
        100: 'C',
        500: 'D',
        1000: 'M'
    }
    // 标识位数
    let digits = 1
    // 结果
    let result = ''
    while(num) {
        let current = num % 10
        if (current < 4) {
            result = map[digits].repeat(current) + result
        } else if (current === 4) {
            result = map[digits] + map[digits * 5] + result
        } else if (current > 4 && current < 9) {
            result = map[digits * 5] + map[digits].repeat(current - 5) + result
        } else {
            result = map[digits] + map[digits * 10] + result
        }
        digits *= 10
        num = Math.trunc(num/10)
    }
    return result
};

//阿拉伯数字转为数字
export function romanToInt(s) {
    let map = {
        'I': 1,
        'V': 5,
        'X': 10,
        'L': 50,
        'C': 100,
        'D': 500,
        'M':1000
    }
    let index = 0
    let result = 0
    let len = s.length
    while(index < len) {
        let current = map[s[index]]
        result += current
        if (index > 0) {
            let before = map[s[index-1]]
            if ((current === 5 || current === 10) && before === 1) {
                result -= 2
            }
            if ((current === 50 || current === 100) && before === 10) {
                result -= 20
            }
            if ((current === 500 || current === 1000) && before === 100) {
                result -= 200
            }
        }
        index++
    }
    return result
};
//过滤地址
export function addr(address){
    return address.substr(0,6)+"...."+address.substr(38,4);
}