const initState={};
try{
    Object.assign(initState, JSON.parse(localStorage.getItem('appConfig') || '{}').pairs[0])
}catch(err){
    console.warn(err.message);
}

/**
 * 交易对的所有信息
 */
export default {
    state: Object.assign({
        perpetual:'',//合约地址
        broker:'',//交易所地址
        fundmork:'',//fundmork
        img:'',//币种图片。
        fullName:'',//全称
        abbreviation:'',//简称
        amountAccuracy:0,//数量的小数精度位
        priceAccuracy:0,//价格的小数精度位

        blockChainAddr:'',//区块链地址。
        exchange:'',//exchange。不变
        usdc:'',//usdc地址。不变
        contractReader:'',//contractReader。不变
    },initState),

    getters: {
        getPerpetual(state) {
            return state.perpetual
        },
    },
    mutations: {
        //修改config文件
        setCurrencyConfig(state,val){
            Object.keys(val).forEach(item=>{
                state[item]=val[item];
            });
        }
    },
    actions: {}
};
