<!--加载中层，引用时，上一层需要相对定位-->
<template>
<div v-if="isShow">
    <div class="loading-mask"></div>
    <div class="loading-wrap flexCenter">
        <!-- <img class="demo-spin-icon-load" :src="require('@/assets/img/loading.svg')" style="width:50px;height:50px;" /> -->
        <div class="pswp__preloader__icn">
            <div class="pswp__preloader__cut">
            <div class="pswp__preloader__donut"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: '',
    model:{
        prop:'isShow',
        event:'change'
    },
    props: {
        //连接钱包弹窗，是否显示
        isShow:{
            type:Boolean,
            default:false
        },
    },
    components: {

    },
    data () {
        return {
        }  
    },
    mounted() {

    },
    methods: {
    },
}
</script>

<style lang='less' scoped>
.loading-mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // background-color: rgba(55, 55, 55, 0.6);
    height: 100%;
    z-index: 10;
    //  background-color: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color:@theme-color;
}
.loading-wrap {
    position: absolute;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}
/*图片加载中 */
.demo-spin-icon-load{
    animation: ani-demo-spin 2s linear infinite;
}
@keyframes ani-demo-spin {
    from { transform: rotate(0deg);}
    50%  { transform: rotate(180deg);}
    to   { transform: rotate(360deg);}
}
/**css加载中 */
.pswp__preloader__icn {
    opacity:0.75;
    width: 50px;
    height: 50px;
    -webkit-animation: clockwise 1000ms linear infinite;
    animation: clockwise 1000ms linear infinite;
}
.pswp__preloader__cut {
    position: relative;
    width: 25px;
    height: 50px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.pswp__preloader__donut {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 3px solid #6179fa;
    border-radius: 50%;
    border-left-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    margin:0;
    -webkit-animation: donut-rotate 2000ms cubic-bezier(.4,0,.22,1) infinite;
    animation: donut-rotate 2000ms cubic-bezier(.4,0,.22,1) infinite;
}

@-webkit-keyframes clockwise {
    0% { -webkit-transform: rotate(0deg) }
    100% { -webkit-transform: rotate(360deg) }
}
@keyframes clockwise {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
}
@-webkit-keyframes donut-rotate {
    0% { -webkit-transform: rotate(0) }
    50% { -webkit-transform: rotate(-140deg) }
    100% { -webkit-transform: rotate(0) }
}
@keyframes donut-rotate {
    0% { transform: rotate(0) }
    50% { transform: rotate(-140deg) }
    100% { transform: rotate(0) }
}
</style>