import store from "@/store";
import router from '@/router';
import config from '@/config';
import { sendMsg, getMkConfig } from '@/api/http'
window.is_Manual_Update=false;//是否是刷新页面执行的socket断开
export default class SocketUtil {
    static ws;      //socket实例

    constructor (parameter) {
        this._init();
    }
    _init () {}

    //连接socket
    static async connectSocket (callback){
        getwebsocket();
        function getwebsocket() { //新建websocket的函数 页面初始化 断开连接时重新调
            getMkConfig().then(res=>{
                _vm.$eventBus.$emit('socket:reconnection')
                if (callback && typeof callback == 'function') {
                  callback()
                }
            })
        }
    }
    //关闭socket
    static async close (callback){
        window.is_Manual_Update=true;
        if(callback && typeof callback=='function'){
            callback();
        }
    }
}