/**
 * 用户钱包账户信息
 */
export default {
    state: {
        marginBalance:0,//保证金余额
        availableMargin:0,//可用保证金
    },
    getters: {
        getAvailableMargin(state){
            return state.availableMargin;
        }
    },
    mutations: {
        setMarginBalance(state,val){
            state.marginBalance=val
        },
        setAvailableMargin(state,val){
            state.availableMargin=val
        }
    },
    actions: {}
};
