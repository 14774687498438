import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import VueClipboard from 'vue-clipboard2';
import ViewUI from 'view-design';
import VueI18n from 'vue-i18n';
import config from '@/config';
import locales from '@/locales'
//注册全局filter
import * as filters from '@/filters'
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})
    // 加载样式
import 'view-design/dist/styles/iview.css';
import '@/assets/css/base.less';

import { VueJsonp } from 'vue-jsonp'    // 网上很多博客引用不加{}，会报错

Vue.use(VueJsonp)

//事件总线
Vue.prototype.$eventBus = new Vue();
Vue.config.ignoredElements = ['minnit-chat']
//加载中组件
import loadingLayer from "@/components/loadingLayer.vue"
Vue.component("loadingLayer", loadingLayer)

Vue.config.productionTip = false;
VueClipboard.config.autoSetContainer = true;
Vue.prototype.$config = config;
Vue.use(VueClipboard);
Vue.use(VueI18n);
Vue.use(ViewUI);

// 自动设置多语言 默认选择浏览器系统语言
const navLang = navigator.language;
const localLang = (navLang === 'zh-CN' || navLang === 'en-US') ? navLang : false;
let lang = localStorage.getItem('language') || localLang || 'en-US';
const i18n = new VueI18n({
    locale: lang,
    messages: locales,
    silentTranslationWarn: true
});
localStorage.setItem('language', lang);

/**
* 限制输入小数
* decimal：限制小数位。0：不允许输入小数点；-1：不限制；其他正整数。默认不限制。
* maxLength：限制最大长度。包含小数位和负号。默认值255
**/
Vue.prototype.prohibitNegative = function (value,decimal=-1, maxLength = 255) {
    if(!value){
        return value;
    }
    if(value=='.' || value=='。'){
        return '';
    }
    value=value.replace("。",'.');
    //限制输入小数点
    if(decimal==0){
        value = value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, "").replace("^[-]{0,1}\d+$","").replace(/[a-zA-Z]+/, '');
    }else{
        value = value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, "").replace("^[-]{0,1}\d+$","").replace(/[a-zA-Z]+/, '');
    }
    //限制小数位
    if(decimal>0){
        let regex=new RegExp(`^\\d+(?:\\.\\d{0,${decimal}})?`,"g"); 
        value = (value && value.match(regex)[0]) || null;
    }
    //限制长度
    if(maxLength > 0 && value && value.length >= maxLength){
        value = value.slice(0,maxLength);
    }
    return value;
}

window._vm = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
