import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import app from './modules/app';
import handicap from './modules/handicap';
import blockchain from './modules/blockchain';
import system from './modules/system';
import mutations from './mutations';
import actions from './actions';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations,
    actions,
    modules: {
        user,
        app,
        handicap,
        blockchain,
        system
    }
});
