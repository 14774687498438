//创建指定范围个数的随机数据
function createAutoData(count,max,min){
    var autoData = new Array();
    var Range = max - min;
    for(var i=0;i<count;i++){
        var Rand = Math.random();   
        autoData.push(min + Math.round(Rand * Range));   
    }
    return autoData;
}
//排序比较器
function compare(prop){
    return function(a,b){
        var value1=a[prop];
        var value2=b[prop];
        return value1-value2;
    }
}
//生成空对象数组
//len：长度，volumeRange：数量生成的大小范围，priceRange：价格生成的大小范围（正负100）。
function getEmptyLen(len=10,volumeRange,priceRange){
    let arr=[];
    for(let i=0;i<len;i++){
        if(volumeRange!=0 || priceRange!=0){
            arr.push({
                amount:createAutoData(1,volumeRange,1)[0],
                price:createAutoData(1,priceRange+100,priceRange-100)[0]
            });
        }else{
            arr.push({
                amount:0,
                price:0
            });
        }
    }
    arr.sort(compare('price')).reverse();
    return arr;
}

//盘口数据，非盘口挂单列表
export default {
    state: {
        newPrice:'',//最新成交价格
        markPrice:'',//标记价格
        book:{
            sell:getEmptyLen(30,0,0),//盘口卖出
            buy:getEmptyLen(30,0,0),//盘口买入       
        },
    },
    getters: {},
    mutations: {
        setNewPrice(state,val){
            if(val){
                state.newPrice=val;
            }
        },
        setMarkPrice(state,val){
            state.markPrice=val;
            if(val && !state.newPrice){
                state.newPrice=val
            }
        },
        setBook(state,val){
            let {buy,sell}=val;
            state.book.buy=buy;
            state.book.sell=sell;
        }
    },
    actions: {}
};
