const mainPannel=(resolve) => require(['@/views/layout/mainPanel.vue'], resolve);
const router = [
  {
    path: '/home',
    redirect: '/'
  },
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Home',
      index: 1
    },
    component: (resolve) => require(['@/views/home/index.vue'], resolve),
    keepAlive: true
  },
  {
    path: '/mission',
    name: 'mission',
    meta: {
      title: 'Mission',
      index: 2
    },
    component: (resolve) => require(['@/views/home/mission.vue'], resolve),
    keepAlive: true
  },
  {
    path: '/faq',
    name: 'faq',
    meta: {
      title: 'FAQ',
      index: 3
    },
    component: (resolve) => require(['@/views/home/faq.vue'], resolve),
    keepAlive: true
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    meta: {
      title: 'Tutorial',
      index: 4
    },
    component: (resolve) => require(['@/views/home/tutorial.vue'], resolve),
    keepAlive: true
  },
  {
    path: '/blog',
    name: 'blog',
    meta: {
      title: 'Blog',
      index: 5
    },
    component: (resolve) => require(['@/views/home/blog_list.vue'], resolve),
    keepAlive: true
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      title: 'News',
      index: 6
    },
    component: (resolve) => require(['@/views/home/news_list.vue'], resolve),
    keepAlive: true
  },
  {
    path: '/MEKE-a-rising-star-in-cryptocurrency-derivatives-trading',
    name: 'blog_detail1',
    meta: {
      title: 'Blog Detail',
      index: 9
    },
    component: (resolve) => require(['@/views/home/blog_detail1.vue'], resolve),
    keepAlive: true
  },
  {
    path: '/BNB-Chain-Perpetual-Platform-MEKE-to-Make-Foray-into-Cryptocurrency-Derivative-Market',
    name: 'blog_detail2',
    meta: {
      title: 'Blog Detail2',
      index: 10
    },
    component: (resolve) => require(['@/views/home/blog_detail2.vue'], resolve),
    keepAlive: true
  },
  {
    path: '/terms_of_use',
    name: 'terms_of_use',
    meta: {
      title: 'Terms of Use',
      index: 11
    },
    component: (resolve) => require(['@/views/home/terms_of_use.vue'], resolve),
    keepAlive: true
  },
  {
    path: '/tutorials_video',
    name: 'tutorials_video',
    meta: {
      title: 'Video Tutorial',
      index: 5
    },
    component: (resolve) => require(['@/views/home/tutorials_video.vue'], resolve),
    keepAlive: true
  },
  {
    path: '/Get_And_Swap_WMEKE',
    name: 'Get_And_Swap_WMEKE',
    meta: {
      title: 'Share reward withdrawal tutorial',
      index: 6
    },
    component: (resolve) => require(['@/views/home/Get_And_Swap_WMEKE.vue'], resolve),
    keepAlive: true
  },
  {
    path: '',
    name: '',
    component: mainPannel,
    children: [
      {
        path: '/trade',
        name: 'trade',
        component: (resolve) => require(['@/views/trade/index.vue'], resolve),
        meta: {
          title: 'Trade',
          index: 1
        },
        keepAlive: false
      }
    ]
  },
  {
    path: '',
    name: '',
    component: mainPannel,
    children: [
      {
        path: '/portfolio',
        name: 'portfolio',
        component: (resolve) => require(['@/views/portfolio/index.vue'], resolve),
        meta: {
          title: '资产组合',
          index: 1
        },
        keepAlive: true
      }
    ]
  },
  {
    path: '',
    name: '',
    component: mainPannel,
    children: [
      {
        path: '/stock',
        name: 'stock',
        component: (resolve) => require(['@/views/stock/index.vue'], resolve),
        meta: {
          title: '现货',
          index: 1
        },
        keepAlive: true
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]
export default router;