import Usdt from '@/utils/build/contracts/TestToken.json'
import Perpetual from '@/utils/build/contracts/Perpetual.json'
import AMM from '@/utils/build/contracts/AMM.json'
import Exchange from '@/utils/build/contracts/Exchange.json'
import ChainLink from '@/utils/build/contracts/OCRPriceConsumer.json'
import ContractReader from '@/utils/build/contracts/ContractReader.json'
import Airdrop from '@/utils/build/contracts/Airdrop.json'
import Web3 from 'web3'

import earnReaderAbi from '@/utils/build/earn/EarnReader.json'
import earnAbi from '@/utils/build/earn/Earn.json'
import mekeAbi from '@/utils/build/earn/MEKE.json'

import { getWalletMargin } from '../api/http'

const contract = require('@truffle/contract')

const cachedContract = (abi) => {
  const contractCls = contract(abi)
  const rawSetProvider = contractCls.setProvider
  const instances = {}
  contractCls.at = async (address) => {
    if (!instances[address]) {
      instances[address] = new contractCls(address)
    }
    return instances[address]
  }

  contractCls.setProvider = (provider) => {
    Object.keys(instances).forEach((key) => {
      delete instances[key]
    })
    return rawSetProvider.call(contractCls, provider)
  }
  contractCls.autoGas = true
  contractCls.gasMultiplier = 2
  return contractCls
}

const cache = {}

class FallbackProvider {
  events = {}
  connected = false
  constructor(providers) {
    this.providers = providers
    this.init()
  }

  init() {
    this.providers.forEach((provider) => {
      provider.on?.('connect', () => {
        if (!this.connected) {
          this.connected = true
          this.emit('connect')
        }
      })

      provider.on?.('reconnect', (err) => {
        if (!this.connected) {
          this.emit('reconnect', err)
        }
      })

      provider.on?.('error', (err) => {
        this.emit('error', err)
      })

      provider.on?.('end', () => {
        this.emit('end')
      })

      provider.send({ jsonrpc: '2.0', id: 1, method: 'eth_chainId', params: [] }, (err) => {
        if (!err && !this.connected) {
          this.connected = true
          this.emit('connect')
        }
      })
    })
  }

  emit(event, ...args) {
    this.events[event]?.(...args)
  }

  on(event, listener) {
    this.events[event] = listener
  }

  off(event) {
    delete this.events[event]
  }

  connect() {
    this.providers.forEach((provider) => provider.connect?.())
  }

  reset() {
    this.providers.forEach((provider) => provider.reset?.())
  }

  reconnect() {
    this.providers.forEach((provider) => provider.reconnect?.())
  }

  disconnect() {
    this.providers.forEach((provider) => provider.disconnect())
  }
  supportsSubscriptions() {
    return false
  }

 async send(payload, callback){
  const evmErrors = [ "contract creation code storage out of gas", "out of gas",
	    "gas uint64 overflow", "max code size exceeded", "invalid jump destination", "bad jump destination",
	    "execution reverted", "reverted", "return data out of bounds", "out of bounds",
	    "stack limit reached 1024 (1023)", "out of stack", "precompiled failed", "invalid input length",
	    "built-in failed", "insufficient balance for transfer", "contract address collision",
	    "nonce uint64 overflow", "invalid code: must not begin with 0xef", "gas uint64 overflow",
	    "max initcode size exceeded", "max code size exceeded", "already known", "invalid sender", "transaction underpriced",
	    "replacement transaction underpriced", "account limit exceeded", "exceeds block gas limit",
	    "negative value", "oversized data", "future transaction tries to replace pending" ];

    var len= this.providers.length;
    for(let i=0; i < len; i++){
      try{
        const provider = this.providers[i];
        const data = await new Promise((resolve, reject)=>provider.send(payload,(err,data)=>{
          if(err){
            if(err instanceof Error){
              reject(err);
            }else{
              reject(new Error(err+""))
            }
          }else if(data.error){
            var lowerMsg=data.error.message.toLowerCase();
            if(evmErrors.some(err=>lowerMsg.indexOf(err)>=0)){
              resolve(data);
            }else{
              reject(new Error(data.error.message));
            }
          }else{
            resolve(data);
          }
        }));
        callback(null,data)
        return;
      }catch(err){
        if(i==len-1){
          callback(err, null);
        }
      }
    }
  }

  sendOld(payload, callback) {
    let callbackUsed = false
    const results = []
    const wrapCallback = (err, data) => {
      results.push([err, data])
      if (!err && !callbackUsed) {
        callbackUsed = true
        return callback(err, data)
      }
      if (results.length === this.providers) {
        return callback(err, data)
      }
    }
    this.providers.forEach((provider) => {
      provider.send(payload, wrapCallback)
    })
  }
}

const createProvider = (url) => {
  if (url.startsWith('ws://') || url.startsWith('wss://')) {
    return new Web3.providers.WebsocketProvider(url, {
      reconnect: {
        auto: true,
        delay: 5000 // ms
      }
    })
  }

  if (url.startsWith('http://') || url.startsWith('https://')) {
    return new Web3.providers.HttpProvider(url)
  }
}

export default class ContractUtil {
  static web3 = new Web3()

  static usdt = cachedContract(Usdt)
  static perpetual = cachedContract(Perpetual)
  static amm = cachedContract(AMM)
  static exchange = cachedContract(Exchange)
  static chainlink = cachedContract(ChainLink)
  static contractReader = cachedContract(ContractReader)
  static airdrop = cachedContract(Airdrop)

  static earn = cachedContract(earnAbi)
  static earnReader = cachedContract(earnReaderAbi)
  static meke = cachedContract(mekeAbi)
  
  static inititalized=false;

  static setProvider(provider) {
    ContractUtil.web3.setProvider(provider)
    ContractUtil.usdt.setProvider(provider)
    ContractUtil.perpetual.setProvider(provider)
    ContractUtil.amm.setProvider(provider)
    ContractUtil.exchange.setProvider(provider)
    ContractUtil.chainlink.setProvider(provider)
    ContractUtil.contractReader.setProvider(provider)
    ContractUtil.airdrop.setProvider(provider)
    ContractUtil.earn.setProvider(provider)
    ContractUtil.earnReader.setProvider(provider)
    ContractUtil.meke.setProvider(provider)
    window.ContractUtil = ContractUtil
  }

  static createProvider(rpcUrls) {
    return new FallbackProvider(Array.from(new Set(rpcUrls), createProvider).filter(Boolean))
  }

  static createContract(abi, provider) {
    const contract = cachedContract(abi)
    contract.setProvider(provider || ContractUtil.web3.provider)
    return contract
  }

  //初始化合约
  static init(urls, callback) {
    if(ContractUtil.inititalized){
      callback();
      return;
    }

    let provider = ContractUtil.createProvider(urls)

    ContractUtil.setProvider(provider)
    provider.on('connect', () => {
      console.log('区块链连接成功', (performance.now() / 1000).toFixed(3) + 's')
      if (callback && typeof callback == 'function') {
        ContractUtil.inititalized = true
        callback()
      }
    })

    provider.on('reconnect', async (err) => {
      console.warn('区块链重连接', err.message)
    })

    provider.on('error', (err) => {
      console.warn('区块链异常错误：', err.message)
    })

    provider.on('end', async (err) => {
      console.warn('区块链连接断开', err.message)
    })
  }

  static async getTraderPosition(contractReaderAddress, perpetual, addr) {
    const key = `${contractReaderAddress}-${perpetual}-${addr}`
    if (!cache[key] || cache[key].expireTime < Date.now()) {
      if (cache[key]) {
        cache[key].expireTime = Date.now() + 5000
      }
      const reader = await ContractUtil.contractReader.at(contractReaderAddress)
      const data = await reader.getTraderPosition.call(perpetual, addr)

      const walletMargin = await getWalletMargin(addr, perpetual)
        .then((res) => res.data)
        .catch((err) => {
          console.warn(err.message)
          return {
            buyTotalPosition: 0,
            sellTotalPosition: 0,
          }
        })

      if (walletMargin.buyTotalPosition + walletMargin.sellTotalPosition === 0) {
        const avaliableMargin = data[5]
        walletMargin.freeMargin = Number((Number(avaliableMargin) / 1e18).toFixed(2))
      }

      const mergedData = { ...data, walletMargin }
      cache[key] = { expireTime: Date.now() + 5000, data: mergedData }
    }
    return cache[key].data
  }
}
