
//所有链接的配置文件
const config = {
    baseUrl: '',
    website: {
        title: 'MEKE',
        keywords: '',
        description: '',
        domain: 'https://meke.fun/',//线上域名
    },
    chainId:'0x15eb',
    alwaysShowPage:false,
};

const bscMainnet = {
  chainId: '0x38', // A 0x-prefixed hexadecimal string。链ID，此处必须转成以‘0x’开头的十六进制。此处十进制为：65
  chainName: 'Binance Smart Chain Mainnet',
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB', // 2-6 characters long
    decimals: 18
  },
  rpcUrls: [
    'https://nameless-wiser-sea.bsc.quiknode.pro/4d86ea973c38874cf271ef161559f040c3197747/',
    'https://bsc-dataseed4.ninicoin.io',
    'https://bsc-dataseed.binance.org',
    'https://bsc-dataseed1.defibit.io'
  ],
  blockExplorerUrls: ['https://bscscan.com/']
}

const opbnbMainnet = {
  chainId: '0xcc', //此处十进制为：204
  chainName: 'opBNB Mainnet',
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB', // 2-6 characters long
    decimals: 18
  },
  rpcUrls: ['https://opbnb-mainnet-rpc.bnbchain.org', 'https://opbnb.publicnode.com', ''],
  blockExplorerUrls: ['https://1rpc.io/opbnb']
}

const opbnbTestnet = {
  chainId: '0x15eb', //此处十进制为：5611
  chainName: 'opBNB Testnet',
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB', // 2-6 characters long
    decimals: 18
  },
  rpcUrls: [
    'https://opbnb-testnet-rpc.bnbchain.org',
  ],
  blockExplorerUrls: ['https://opbnbscan.com/']
}

const bscTestnet = {
  chainId: '0x61', //此处十进制为：97
  chainName: 'BSC Testnet',
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB', // 2-6 characters long
    decimals: 18
  },
  rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/', 'https://data-seed-prebsc-2-s2.binance.org:8545/'],
  blockExplorerUrls: ['https://testnet.bscscan.com/']
}

config.chainInfos = {
  bscMainnet,
  bscTestnet,
  opbnbMainnet,
  opbnbTestnet,
}

if (process.env.NODE_ENV !== 'production') {
  config.baseUrl = 'http://localhost:8900/mk'; //服务器接口地址
  config.chainInfo = opbnbTestnet
} else {
  config.baseUrl = window.meke_config.baseUrl //服务器接口地址
  config.chainInfo = Object.values(config.chainInfos).find((c) => c.chainId == window.meke_config.chainId);
}

config.chainId= config.chainInfo.chainId;

export default config;