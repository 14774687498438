/**
 * 存数据 userNameKey:用户名+键
 * userNameKey:用户名键，value：存的值
 */
export function setSessionStorage(userNameKey, value) {
    sessionStorage.setItem(userNameKey,value);
}

/**
 * 取数据 userNameKey:用户名+键
 * userNameKey:用户名键
 */
export function getSessionStorage(userNameKey) {
    return JSON.parse(sessionStorage.getItem(userNameKey));
}

//生成随机字符串
//len：长度
export function randomString(len) {
　　len = len || 32;
　　var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
　　var maxPos = $chars.length;
　　var pwd = '';
　　for (var i = 0; i < len; i++) {
　　　　pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
　　}
　　return pwd;
}

//生成随机字符串+当前时间
//len：长度
export function randomStringOrTime(len) {
　　len = len || 32;
　　var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
　　var maxPos = $chars.length;
　　var pwd = '';
　　for (var i = 0; i < len; i++) {
　　　　pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
　　}
　　return pwd+(new Date().getTime());
}

// 两个浮点数求和
export function accAdd(num1,num2){
    var r1,r2,m;
    try{
        r1 = num1.toString().split('.')[1].length;
    }catch(e){
        r1 = 0;
    }
    try{
        r2=num2.toString().split(".")[1].length;
    }catch(e){
        r2=0;
    }
    m=Math.pow(10,Math.max(r1,r2));
    // return (num1*m+num2*m)/m;
    return Math.round(num1*m+num2*m)/m;
}

// 两个浮点数相减
export function accSub(num1,num2){
    var r1,r2,m,n;
    try{
        r1 = num1.toString().split('.')[1].length;
    }catch(e){
        r1 = 0;
    }
    try{
        r2=num2.toString().split(".")[1].length;
    }catch(e){
        r2=0;
    }
    m=Math.pow(10,Math.max(r1,r2));
    n=(r1>=r2)?r1:r2;
    return (Math.round(num1*m-num2*m)/m).toFixed(n);
}

// 两数相乘
export function accMul(num1,num2){
    var m=0,s1=num1.toString(),s2=num2.toString();
    try{m+=s1.split(".")[1].length}catch(e){};
    try{m+=s2.split(".")[1].length}catch(e){};
    return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m);
}

// 两数相除
export function accDiv(num1,num2){
    var t1,t2,r1,r2;
    try{
        t1 = num1.toString().split('.')[1].length;
    }catch(e){
        t1 = 0;
    }
    try{
        t2=num2.toString().split(".")[1].length;
    }catch(e){
        t2=0;
    }
    r1=Number(num1.toString().replace(".",""));
    r2=Number(num2.toString().replace(".",""));
    return (r1/r2)*Math.pow(10,t2-t1);
}

// 取最大值：
//arr：数组对象，key：对象的某一项
export function maxArrObj(arr,key){
    if(arr.length==0){
        return 0;
    }
    let max=arr[0][key];
    arr.forEach(item=>{
        if(item[key] > max){
            max=item[key];
        }
    });
    return max;
    // return Math.max.apply(Math, arr.map(function(o) {return o[key]}));
}

// 取最小值：
//arr：数组对象，key：对象的某一项
export function minArrObj(arr,key){
    if(arr.length==0){
        return 0;
    }
    let min=arr[0][key];
    arr.forEach(item=>{
        if(min==0 && item[key]!=0){     //如果存在值，那么最小值不以零为最小值。不包含负数
            min=item[key];
        }
        if(item[key] < min && item[key]!=0){    //零不做判断最小值
            min=item[key];
        }
    });
    return min;
    // return Math.min.apply(Math, arr.map(function(o) {return o[key]}));
}
/**
 * 判断字符是否为空
 * str：字符串
 */
function isNullorEmpty(str) {
    return (str == null || str == "" || str == undefined || str == "undefined") && (str != 0 || str != "0") ? true : false;
}
//格式化
export function toFixed(val,len=0){
    if(isNullorEmpty(val)){
        return '-';
    }else{
        // return val && val != 0 ? (Number(val)).toFixed(len) : '0';
        return val && val != 0 ? (accDiv(Math.floor(accMul(Number(val),Math.pow(10,len))),Math.pow(10,len))).toFixed(len) : '0';
    }
}

//百分比计算公式
//a：分子，b：分母
export function calcPercent(a,b){
    var c=a/b*100;
    return Math.min(100, Math.max(1, c));
}

//数值过千加逗号，并保留几位小数
//num：数值，len：几位小数
export function numFormat(num,len) {
    // num=num && num != 0 ? Number(num).toFixed(len) : 0;
    num=num && num != 0 ? (Math.floor(Number(num) * Math.pow(10,len)) / Math.pow(10,len)).toFixed(len) : 0;
    //toFixed属于四舍五入，如果不需要，想直接舍去，可以使用Math.floor(5)先向下取整，再用toFixed。
    var c = (num.toString().indexOf ('.') !== -1) ? num.toLocaleString() : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    return c;
}

//返回两个时间的差
//oldT：当前时间，newT：目的时间
export function timeDiff(oldT,newT) {
    var nowtime = new Date(oldT),
        endtime = new Date(newT);
    var lefttime = endtime.getTime() - nowtime.getTime(),
        leftd = Math.floor(lefttime/(1000*60*60*24)),
        lefth = Math.floor(lefttime/(1000*60*60)%24),
        leftm = Math.floor(lefttime/(1000*60)%60),
        lefts = Math.floor(lefttime/1000%60);
    // return leftd + "天" + lefth + ":" + leftm + ":" + lefts;
    return `${leftm>=10 ? leftm : '0'+leftm}:${lefts>=10 ?lefts : '0'+lefts}`;
}
//合并数组对象，相同key键的某一项数组求和
//arr：数组，judgeKey：判断的键,mergeKey：求和的键,type：类型
export function mergeArr(arr,judgeKey,mergeKey,type){
    let result=[];
    arr.forEach(item=>{
        let index=-1;
        result.forEach((m,n)=>{
            if(m[judgeKey]==item[judgeKey] && m[type]==item[type]){
                index=n;
            }
        });
        if(index!=-1){
            result[index][mergeKey]+=item[mergeKey];
        }else{
            result.push(item);
        }
    });
    return result;
}

//获取url链接中的参数，二维码中的参数
export function getUrlParam(url,key) {
    var params={};
    url=decodeURIComponent(url);//进行base64解码
    if(url.split("?").length<=1){
        return '';
    }
    var arr=url.split("?")[1].split("&");
    arr.forEach(function(item){
        params[item.split("=")[0]]=item.split("=")[1];
    });
    return key?params[key]:params;
}

//创建指定范围个数的随机数据
export function createAutoData(count,max,min){
    var autoData = new Array();
    var Range = max - min;
    for(var i=0;i<count;i++){
        var Rand = Math.random();
        autoData.push(min + Math.round(Rand * Range));
    }
    return autoData;
}
//排序
export function compare(prop){
    return function(a,b){
        var value1=a[prop];
        var value2=b[prop];
        return value1-value2;
    }
}

/**
* 获取当前时间，当前日期。也可以用作于格式化时间。
* timeStamp:时间戳
*/
export function getThisTime(timeStamp) {
    var myDate = timeStamp ? new Date(parseInt(timeStamp) * 1000) : new Date();
    function p(s) {
        return s < 10 ? '0' + s : s;
    }
    return {
        'year': myDate.getFullYear(),
        'month': myDate.getMonth() + 1,
        'date': myDate.getDate(),
        'h': myDate.getHours(),
        'm': myDate.getMinutes(),
        's': myDate.getSeconds(),
        'now': myDate.getFullYear() + '-' + p(myDate.getMonth() + 1) + "-" + p(myDate.getDate()) + " " + p(myDate.getHours()) + ':' + p(myDate.getMinutes()) + ":" + p(myDate.getSeconds())
    };
}

/**
 * 横向拖动触发滚动条拖动
 * container：jquery选择器
 */
export function dragMoveX(container) {
    var flag;
    var downX;
    var scrollLeft;
    //鼠标按下
    $(document.body).on("mousedown", container, function (event) {
        // console.log('mousedown')
        flag = true;
        downX = event.clientX;
        scrollLeft = $(this).scrollLeft();
    });
    //鼠标移动
    $(document).on("mousemove", function (event) {
        // console.log('mousemove')
        if (flag) {
            var moveX = event.clientX;
            var scrollX = moveX - downX;
            // console.log("moveX" + moveX);
            // console.log("scrollX" + scrollX);
            if (scrollX < 0 && scrollLeft > 0) {
                $(container).scrollLeft(scrollLeft - scrollX)
            }
            else {
                $(container).scrollLeft(scrollLeft - scrollX)
            }
        }
    });
    //鼠标释放
    $(document).on("mouseup", function () {
        // console.log('mouseup')
        flag = false;
    });
    /**
     * 注意：与 mouseout 事件不同，mouseleave 事件只有在鼠标指针离开被选元素时被触发，mouseout 事件在鼠标指针离开任意子元素时也会被触发。参见页面底部演示实例。
     * 所以：如果mouseout的子元素存在溢出，并添加了超出加滚动，那么刚进入也会触发该事件，所以这里就不能使用。
     * */
    //鼠标移出元素
    $(container).on("mouseleave", function (event) {
        // console.log('mouseleave')
        if (event.pageX < 0 || event.pageX > document.body.offsetWidth) {
            flag = false;
        }         
    });
}

// 两个浮点数求和
export function jia(num1,num2){
    var r1,r2,m;
    try{
        r1 = num1.toString().split('.')[1].length;
    }catch(e){
        r1 = 0;
    }
    try{
        r2=num2.toString().split(".")[1].length;
    }catch(e){
        r2=0;
    }
    m=Math.pow(10,Math.max(r1,r2));
    // return (num1*m+num2*m)/m;
    return Math.round(num1*m+num2*m)/m;
}

// 两个浮点数相减
export function jian(num1,num2){
    var r1,r2,m,n;
    try{
        r1 = num1.toString().split('.')[1].length;
    }catch(e){
        r1 = 0;
    }
    try{
        r2=num2.toString().split(".")[1].length;
    }catch(e){
        r2=0;
    }
    m=Math.pow(10,Math.max(r1,r2));
    n=(r1>=r2)?r1:r2;
    return (Math.round(num1*m-num2*m)/m).toFixed(n);
}

// 两数相除
export function chu(num1,num2){
    var t1,t2,r1,r2;
    try{
        t1 = num1.toString().split('.')[1].length;
    }catch(e){
        t1 = 0;
    }
    try{
        t2=num2.toString().split(".")[1].length;
    }catch(e){
        t2=0;
    }
    r1=Number(num1.toString().replace(".",""));
    r2=Number(num2.toString().replace(".",""));
    return (r1/r2)*Math.pow(10,t2-t1);
}

// 两数相乘
export function cheng(num1,num2){
    var m=0,s1=num1.toString(),s2=num2.toString();
    try{m+=s1.split(".")[1].length}catch(e){};
    try{m+=s2.split(".")[1].length}catch(e){};
    return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m);
}


export function clearStruct(data) {
  if (data && typeof data == 'object') {
    if ('toBigInt' in data) {
      return data.toBigInt().toString();
    }
    
    if("toNumber" in data){
        return data.toString();
    }

    var keys = Object.keys(data)
    var stringKeys = keys.filter((key) => !isFinite(key))
    if (stringKeys.length == 0) {
      var obj = []
      data.forEach((v) => obj.push(clearStruct(v)))
      return obj
    } else {
      var obj = {}
      stringKeys.forEach((key) => {
        obj[key] = clearStruct(data[key])
      })
      return obj
    }
  }
  return data
}