import config from "@/config";
import store from "@/store";
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink"
import { sendMsg,userLogin } from "@/api/http"

import Usdt from '@/utils/build/contracts/TestToken.json'
import Perpetual from '@/utils/build/contracts/Perpetual.json'
import Exchange from "@/utils/build/contracts/Exchange.json"
import Airdrop from "@/utils/build/contracts/Airdrop.json"
import Mining from '@/utils/build/contracts/Mining.json'
import Web3 from 'web3'

import earnAbi from '@/utils/build/earn/Earn.json'
import mekeAbi from '@/utils/build/earn/MEKE.json'

const contract = require("@truffle/contract");

const cachedContract=(abi)=>{
    const contractCls=contract(abi)
    const rawSetProvider=contractCls.setProvider
    const instances={}
    contractCls.at=async (address)=>{
        if(!instances[address]){
            try{
                instances[address]=new contractCls(address)
                await contractCls.detectNetwork().catch(err=>console.warn(err.message))
            }catch(err){
                await new Promise((resolve,reject)=>{
                    setTimeout(async ()=>{
                        instances[address]=new contractCls(address)
                        await contractCls.detectNetwork().catch(reject)
                        resolve(instances[address])
                    },2000)
                })
            }
        }
        return instances[address]
    }
    
    contractCls.setProvider=(provider)=>{
        Object.keys(instances).forEach(key=>{
            delete instances[key]
        })
        return rawSetProvider.call(contractCls,provider)
    }
    contractCls.autoGas=true
    contractCls.gasMultiplier=2
    return contractCls
}

export default class WalletUtil {
  static web3 = new Web3()
  static provider

  static usdt = cachedContract(Usdt)
  static perpetual = cachedContract(Perpetual)
  static exchange = cachedContract(Exchange)
  static airdrop = cachedContract(Airdrop)
  static mining = cachedContract(Mining)

  static earn = cachedContract(earnAbi)
  static meke = cachedContract(mekeAbi)

  static setProvider(provider) {
    WalletUtil.provider = provider
    WalletUtil.web3.setProvider(provider)
    WalletUtil.usdt.setProvider(provider)
    WalletUtil.perpetual.setProvider(provider)
    WalletUtil.exchange.setProvider(provider)
    WalletUtil.airdrop.setProvider(provider)
    WalletUtil.mining.setProvider(provider)
    WalletUtil.earn.setProvider(provider)
    WalletUtil.meke.setProvider(provider)
    window.web3 = WalletUtil.web3
    window.WalletUtil = WalletUtil
  }

  static createContract(abi, provider) {
    const contract = cachedContract(abi)
    contract.setProvider(provider || WalletUtil.provider)
    return contract
  }

  //退出登录
  static async onDisconnect() {
    if (WalletUtil.provider.close) {
      await WalletUtil.provider.close().catch((err) => console.log(err.message))
      WalletUtil.provider = null
    }
  }
  //连接插件钱包
  static async walletInit(callback) {
    console.log('web3的版本：', Web3.version)
    let walletActive = JSON.parse(sessionStorage.getItem('walletActive'))
    switch (walletActive.name) {
      case 'WalletConnect':
        await loginCode()
        break
      default:
        await loginMetaMask()
        break
    }
    //链id
    let chainId = await WalletUtil.web3.eth.getChainId()
    store.commit('setChainId', { chainId })
    store.commit('setWalletActiveName', walletActive.name)
    console.log('WalletInfo:', { chainId, walletName: walletActive.name })

    WalletUtil.provider.on('accountsChanged', (accounts) => {
      console.log('钱包切换')
      window.location.reload()
    })

    WalletUtil.provider.on('chainChanged', async () => {
      let chainId = await WalletUtil.web3.eth.getChainId()
      console.log('链切换:' + chainId)
      store.commit('setChainId', { chainId })
    })
    //账户断开的方法
    WalletUtil.provider.on('disconnect', (code, reason) => {
      console.log('账户退出', { code, reason })
      store.commit('exitLogin')
      //发送退出登录消息
      sendMsg({ cmd: 'logout' })
      WalletUtil.onDisconnect()
    })
    if (callback && typeof callback == 'function') {
      callback()
    }

    async function setProvider(provider) {
      WalletUtil.setProvider(provider)
      try {
        // WalletUtil.web3.eth.handleRevert = true;
        let addrs = await WalletUtil.web3.eth.getAccounts()
        let addr = addrs[0].toLowerCase()
        userLogin(addr).then((res) => {
          store.commit('login', { isInit: true, address: addr })
        })
      } catch (err) {
        console.warn(err.message)
      }
    }

    //二维码
    async function loginCode() {
      const defaultChainId = parseInt(config.chainId)
      const provider = new WalletConnectProvider({
        chainId: defaultChainId,
        rpc: Object.fromEntries(
          Object.values(config.chainInfos).map(({ chainId, rpcUrls }) => [Number(chainId), rpcUrls[0]])
        )
      })
      await provider.enable()
      await setProvider(provider)
    }

    //MetaMask
    async function loginMetaMask() {
      var web3Provider
      if (window.ethereum) {
        web3Provider = window.ethereum
        try {
          await web3Provider.enable().catch((err) => {
            console.warn(err.message)
          })
          // 请求用户授权
          await web3Provider.request({ method: 'eth_requestAccounts' })
        } catch (error) {
          // 用户不授权时
          console.error('User denied account access')
        }
      } else if (window.web3) {
        // 老版 MetaMask Legacy dapp browsers...
        web3Provider = window.web3.currentProvider
      } else {
        web3Provider = new Web3.providers.WebsocketProvider(store.state.blockchain.blockChainAddr)
      }
      await setProvider(web3Provider)
    }

    //Coinbase
    async function loginCoinbase() {
      const defaultChainId = parseInt(config.chainId)
      const walletLink = new WalletLink({
        appName: config.website.title,
        appLogoUrl: 'https://example.com/logo.png',
        darkMode: 'false'
      })

      const provider = walletLink.makeWeb3Provider(config.chainInfo.rpcUrls[0], defaultChainId)
      await provider.enable()
      await setProvider(provider)
    }
  }
}

window.addEventListener('unhandledrejection',(e)=>{
    if(e.reason.message==="Cannot read properties of undefined (reading 'importKey')" && !window.SubtleCrypto){
        console.warn("局域网无法使用, 请使用公网或localhost", e.reason.message)
        e.preventDefault()
        return false
    }
})