const lang = {
  copySuccess: '复制成功!',
  operationTooFast: '操作太快!',
  repeatSubmission: '重复提交!',
  loading: '正在加载中...',
  operationSucceeded: '操作成功!',
  operationFailed: '操作失败!',
  underConstruction: '建设中...',
  domain: 'MEKE',
  locale: 'zh',
  language: '中文',
  contract: '合约',
  direction: '方向',
  perpetuals: '永续合约',
  margin: '保证金',
  marginRate: '保证金率',
  positionPrice: '持仓均价',
  signPrice: '标记价格',
  strongPrice: '强平价格',
  fundRate: '资金费率',
  serviceCharge: '手续费',
  profitLoss: '盈亏',
  operation: '操作',
  portfolio: '资产组合',
  connectWallet: '连接钱包',
  trade: '合约交易',
  markets: '交易对',
  indexPrice: '指数价格',
  oraclePrice: '预言机价格',
  change24h: '24小时变动',
  openInterest: '未平仓合约',
  funding1h: '1小时资金',
  funding8h: '8小时资金',
  volume24h: '24小时交易量',
  trade24h: '24小时交易数',
  amount24h: '24小时成交额',
  nextFunding: '下一笔资金',
  connectWalletIntroduce: '连接您的钱包，以存入资金和开始交易。',
  market: '市价',
  limit: '限价',
  stop: '止损',
  stopLimit: '止损限价',
  trailingStop: '追踪止损',
  sell: '卖出',
  buy: '买入',
  _sell: '卖',
  _buy: '买',
  amount: '数量',
  orderAmount: '订单金额',
  amountIntroduce: '要买入或卖出的ETH的金额。这是您在将订单全部成交时头寸将增加或减少的金额，而不是您得到的头寸金额。',
  or: '或',
  leverage: '杠杆',
  upto25x: '最高25倍',
  short: '做多',
  long: '做空',
  expectedPrice: '预期价格',
  expectedPriceIntroduce:
    '您订单的预期执行价格。在匹配引擎处理您的订单之前，由于订单簿可能会发生变化，因此此价格仅是估计值。',
  priceImpact: '价格差',
  priceImpactTitle: '价格影响因素',
  priceImpactIntroduce: '订单与投标方或询价方最佳订单之间预期执行价格的差值。交易量越大，价格影响因素就越大。',
  fee: '交易费率',
  taker: '吃单',
  feeIntroduce: 'dYdX的费用根据流动性类型收取。挂单订单的费用比吃单订单的费用低。',
  total: '合计',
  placeMarketOrder: '下市价订单',
  placeLimitOrder: '下限价订单',
  placeStopOrder: '下止损单',
  limitPrice: '限价',
  limitPriceIntroduce:
    '此订单只能以指定的限价或更高的价格全部成交。如果您的订单在下单时交叉，则您的订单将以最优惠的价格全部成交任何交叉订单。',
  stopPrice: '触发价格',
  stopPriceTitle: '停损价',
  stopPriceIntroduce:
    '当此市场的指数价格超过您的停损价时，您的止损单将转换为限价订单（止损限价订单）或作为市价订单执行（追踪止损单）。',
  trailingPercent: '追踪百分比',
  trailingPercentIntroduce:
    '追踪百分比设置您的停损价将“追踪”指数价格的百分比。只要指数价格变动有利于您的头寸，您订单的停损价就将根据您指定的追踪百分比进行更新。',
  feePercent: '费用百分比',

  book: '盘口',
  trades: '最新成交',
  size: '数量',
  price: '价格',
  mine: '我的订单',
  spread: '价差',
  time: '时间',

  depth: '深度',
  funding: '资金',
  detail: '详情',

  marketName: '市场名称',
  tickSize: '最小报价数量',
  tickIntroduce: '最小报价数量是此市场上的最小价格变动。',
  stepSize: '步骤数量',
  stepSizeIntroduce: '步骤数量是此市场上的订单金额允许的最小因子。',
  minimumOrderSize: '最小订单数量',
  maximumLeverage: '最大杠杆',
  maximumLeverageIntroduce: '此市场允许的最大杠杆。为了限制风险，最大杠杆会在一定安全阈值后随头寸数量线性减少。',
  MMfraction: '维持保证金分数',
  MMfractionIntroduce:
    '保证金分数的计算方式是用您的头寸名义价值除以账户净值。如果您的保证金分数超过维持保证金分数，您的头寸将被自动平仓（清算），并按至少1',
  IMfraction: '初始保证金分数',
  IMfractionIntroduce:
    '杠杆分数的计算方式是用您的头寸名义价值除以账户净值。如果您的杠杆分数超过初始杠杆分数，您将不能再增加头寸。为了限制风险，初始杠杆分数会在一定安全阈值后随头寸数量线性增加。',
  IIMfraction: '初始边际增量',
  IPsize: '增量位置大小',
  BPsize: '基线位置大小',
  MPsize: '最大头寸数量',

  position: '当前持仓',
  position2: '持仓',
  orders: '订单',
  fills: '全部成交',

  none: '暂无',
  leverageIntroduce: '杠杆将更改您的收益或损失乘数。增加您的杠杆会增加您的交易在相同价格波动下获利/亏损的金额。',
  strongFlatPrice: '强平价格',
  SFPintroduce:
    '如果ETH的预报价格低于强平价格，则您的头寸会被清算。清算后，您的头寸将被自动平仓，并按至少1%来对清算费用进行估价（可能会更高，具体取决于清算时的流动性）。',
  UPAloss: '未实现盈亏',
  UPAlossTitle: '未实现盈亏',
  UPAlossIntroduce:
    '如果您以当前指数价格将当前头寸剩余部分平仓，则为总损益。如果将头寸平仓或更改头寸侧（从多头到空头，或从空头到多头），则重置。',
  RPAloss: '已实现盈亏',
  RPAlossTitle: '已实现盈亏',
  RPAlossIntroduce:
    '此头寸通过部分平仓、费用和资金实现的总损益。如果将头寸完全平仓或更改头寸侧（从多头到空头，或从空头到多头），则重置。',
  openPrice: '开仓价格',
  markPrice: '标记价格',
  available: '可用',

  //表格
  youNoHave: '您没有',
  fullStop: '。',
  systemStatus: '系统状态',
  side: '买/卖',
  amountFilled: '已匹配/数量',
  goodTil: '有效至',
  goodTilIntroduce: '您的订单到期之前的时间。',

  type: '类型',
  totalFee: '合计/交易费率',
  liquidity: '挂单/吃单',
  liquidityTitle: '流动性',
  liquidityIntroduce:
    '从订单簿获取流动性的交易（例如市价或交叉限价订单），将作为吃单订单执行。增加订单簿流动性并由另一方全部成交的限价订单，将作为挂单订单全部成交。',

  //资产组合
  views: '视图',
  hide: '隐藏',
  overview: '概述',
  positions: '头寸',
  history: '历史记录',

  buyPower: '购买力',
  pastWeek: '上周',
  value: '价值',
  usage: '使用',
  freeCollateral: '可抵押品',
  marketName: '市场',
  openPositions: '敞口头寸',
  LiqPriceOracle: '挂单/吃单价格/Oracle',
  LiqPriceOracleTitle: '清算价格',
  LiqPriceOracleIntroduce:
    '如果头寸市场的预言机价格低于清算价格，则您的头寸会被清算。清算后，您的头寸将被自动平仓，并至少收取1%的清算费用（可能会更高，具体取决于清算时的流动性，即挂单/吃单）。',
  averageOpenClose: '开仓均价/平仓',

  //fees
  makerFee: '挂单费',
  takerFee: '吃单费',
  maker: '挂单',
  taker: '吃单',
  transfers: '转账',
  amountPrice: '数量/价格',
  fundingRate: '资金费率',
  action: '操作',
  amountFee: '数量/费率',
  transaction: '交易',
  connectYourWallet: '连接钱包',
  aboutWallets: '关于钱包',
  skipForNow: '现在跳过',
  copyAddr: '复制地址',
  exitConnect: '断开连接',
  totalSize: '总数量',
  totalCost: '总成本',
  advanced: '高级',
  timeInForce: '有效截止时间',
  minute: '分钟',
  hour: '小时',
  day: '天',
  week: '周',
  execution: '执行',
  postOnly: '仅挂单',
  fillOrKill: '全部成交否则取消指令',
  postOnlyDetail: '启用了只挂单的订单只能作为挂单订单下达。如果订单在下单时与其他订单交叉，它将被自动取消。',
  fillOrKillDetail: '在执行时，全部成交否则取消指令订单必须全部成交，否则它们将被自动取消。',
  goodTilTime: '有效截止时间',
  fillOrKill: '全部成交否则取消指令',
  immediateOrCancel: '立即否则取消',
  assets: '资产',
  warnTips: '此交易将导致您的头寸杠杆无效。',
  warnTips2: '没有足够的流动性来全部成交此市价订单，请尝试较小的订单金额。',
  lowerPriceWarn: '下单价格不能低于 ',
  highPriceWarn: '下单价格不能高于 ',
  highLeverageWarn: '杠杆不能高于 ',
  amounExceedWarn: 'ETH 数量不能大于 ',

  account: '账户',
  withdraw: '取款',
  deposit: '存款',
  marginBalance: '保证金余额',
  freeMargin: '可用保证金',
  marginUtilization: '保证金使用率',
  open: '展开',
  fold: '折叠',
  cannel: '撤销',
  cancelOrderConfirm: '您确认撤销该订单？',
  bill: '账单',
  closePosition: '平仓',
  share: '分享',
  neverClose: '永不平仓',
  payment: '支付',
  about: '关于',
  company: '公司',
  product: '产品',
  leveragedTrading: '杠杆交易',
  developer: '开发者',
  toTrade: '去交易',
  enable: '授权',
  approveUSDC: '首次使用时，必须授权USDT。此操作仅需执行一次。',
  confirm: '确认',
  cancel: '取消',
  notAvailableMargin: '可用保证金不足！',
  confirmWithdraw: '确认取款',
  confirmDeposit: '确认存款',
  withdrawSuccess: '取款成功!',
  withdrawalFailed: '取款失败!',
  depositSuccess: '存款成功！',
  depositFailed: '存款失败！',
  promotionSharing: '推广分享',
  cctivationSucceeded: '激活成功',
  activateVIP: '激活VIP',
  promotionRewardDesc: '推广并邀请好友可获得优惠',
  promotionLink: '推广链接',
  promotionPreference: '推广优惠',
  promotionCodeDesc: '邀请人的推广码',
  copyLink: '复制链接',
  submit: '提交',
  discount0_0: '1.获取测试USDT≥10万，每推一人额外奖励被推人空投一MEKE的2%，',
  discount0_em: '可直接提现交易；',
  discount0_1: '且当被推人获取测试USDT≥10万,按照推荐成功人数每人再额外奖励推荐者空投一MEKE的1‰。',
  discountOne: '2.推广成功享受被邀请者每笔交易手续费的30%作为返佣。',
  discountTwo: '3.通过邀请链接注册的邀请者交易手续费将减免为原来的60%。',
  decentralization: '去中心化',
  tradingPerpetualDetail: '交易永续合约，费用低，流动性强，高达25倍的杠杆。',
  deposit10Start: '存入$10即可开始。',
  nowOnline: '现已上线',
  startTradingPerpetualContract: '开始交易永续合约',
  newMarketIntroduce: '我们将在2021推出新的永续合约市场。',
  newMarketIntroduce2: '我们将在未来推出更快、更优质的永续合约市场',
  newMarketIntroduce2_0: '我们将在未来推出',
  newMarketIntroduce2_1: '更快、更优质',
  newMarketIntroduce2_2: '的永续合约市场',
  callAllUsers: '召集所有用户',
  waitingOverDetail: 'Layer 2已来',
  waitingOverDetail2: 'Layer 2已来',
  maxExchangeDetail: '我们建立了有史以来最快、最强大的去中心化交易所。',
  maxExchangeDetail2: '我们正在建立有史以来最快、最强大的去中心化交易所',
  maxExchangeDetail2_0: '我们正在建立有史以',
  maxExchangeDetail2_1: '最快、最强大的',
  maxExchangeDetail2_2: '去中心化交易所',
  web3Error: '请用支持Web3的浏览器打开',
  nodeError: '节点连接失败，网络通畅后再试',
  /*首页底部 */
  Blog: '博客',
  Stay_up_to_date: '了解最新动态',
  frequently_asked_questions: '常见问题解答',
  course: '教程',
  Watch_others_use_meke: '观看他人使用MEKE',
  Help_center: '帮助中心',
  mekeTips: '使用MEKE的提示和技巧',
  Terms_of_use: '使用条款',
  Platform_usage_rules: '平台使用规则',
  Privacy_policy: '隐私政策',
  Our_data_policy: '我们的数据政策',
  Promotional_Terms: '促销条件',
  Our_terms_for_promotions: '我们的促销条件',
  mission: '使命',
  what_building: '我们正在建造什么？为什么？',
  Join_us: '加入我们',
  We_are_actively_recruiting: '我们正在积极招聘！',
  brand: '品牌',
  Download_logos_and_assets: '下载徽标和资产',
  Perpetual_contract: '永续合约',
  layer2_supported: 'Layer 2 即时交易，由MEKE提供支持',
  Leveraged_Trading: '杠杆交易',
  eth_main_network: '以太坊主网上的现货、杠杆和合约交易',
  document: '文档',
  Trading_using_our_API: '使用我们的API进行交易',
  our_developer_etc: '查看我们的智能合约、开发人员工具等',
  status: '状态',
  Monitor_exchange_status: '监控交易所状态',
  Grasp_the_pulse: '把握脉搏',
  Subscribe_to_tutorials: '订阅教程',
  Post_on_our_forum: '在我们的论坛上发帖',
  View_our_vacancies: '查看我们的职位空缺',
  WhitePaper: '白皮书',
  WhitePaperDesc: '白皮书',

  /**首页中部 */
  itemTitle1: '安全高效',
  itemTitle2: '交易上链',
  itemTitle3: '公开透明',
  itemTitle4: '用户友好',
  itemTitle5: '资产安全由智能合约保障',

  WelcomeToJoinMekeClub_0: '欢迎加入',
  WelcomeToJoinMekeClub_1: 'MEKE',
  WelcomeToJoinMekeClub_2: '俱乐部',
  MekeDesc:
    'MEKE是一个去中心化的加密货币衍生品交易平台部署在币安智能链上，安全、高效、透明。重新开始您的永续合约交易生涯。',

  mekeIntroduce1: '费用低，无Gas成本',
  mekeIntroducecontent1: '超低费用。存入 Layer 2 后，您就不再需要为每笔交易支付费用。',
  mekeIntroduce2: '超快',
  mekeIntroducecontent2: '闪电速度。交易可立即执行并且数秒之内在区块链上确认。',
  mekeIntroduce3: '快速取款',
  mekeIntroducecontent3: '快速提款。无需等待，即可从 Layer 2 取款。',
  mekeIntroduce4: '移动友好',
  mekeIntroducecontent4: '支持移动端。我们重新设计了交易所，因此您现在可以随时随地使用。',
  mekeIntroduce5: '安全和私密',
  mekeIntroducecontent5: '安全私密。MEKE的 Layer 2 通过零知证明提高了安全性和隐私性。',
  mekeIntroduce6: '交叉保证金',
  mekeIntroducecontent6: '交叉保证金。一个账户可以访问不同交易对的仓位。',
  startTrading: '开始交易',
  startTrading2: '开始交易',
  StartTrading2Desc: '体验和中心化永续合约交易一样的流畅交易体验。',
  toggleChainWarn: '请将您的钱包网络设置为 ',
  switchingChain: '切换链',
  now8FundingRate: '当前8小时利率',
  closingPosition: '等待中...',
  placingMarketOrder: '正在下市价订单...',
  placingLimitOrder: '正在下限价订单...',
  processing: '处理中...',
  successfulChaining: '成功',
  uplinkFailure: '失败',
  insufficientLiquidity: '流动性不足!',
  notice: '通知',
  noNewNotice: '没有新通知。',
  feedback: '反馈',
  feedDetail: '告诉我们如何建立更好的交易体验。',
  send: '发送',
  socketDisconnect: '连接已断开！请重新连接才能继续使用。',
  gasFee: '上链手续费',
  networkNormal: '网络已恢复正常!',
  networkUnavailable: '网络连接不可用!',
  onConnection: '正在连接...',
  retry: '重试',
  orderNotMatched: '未匹配到订单!',
  rateDiscount: '费率折扣',
  grade: '等级',
  currencyHolding: '持币量',
  discount: '折扣',
  costStructure: '费用结构',
  trade30: '交易量（30天）',
  mining: '挖矿',
  withdrawal: '提现',
  stableConnection: '系统连接正常',
  instableConnection: '系统连接不稳定',
  youNotTest: '您未有内测资格!',
  cumulativeIncome: '最多可获取MEKE',
  liquidityMiningIncome: '流动性挖矿收益',
  tradingMiningIncome: '交易挖矿收益',
  miningWithdrawal: '挖矿提现',
  walletBalance: '钱包余额',
  availableWalletBalance: '可用钱包余额',
  walletBalanceRate: '钱包余额使用率',
  Insufficient_available_wallet_balance: '可用钱包余额不足!',
  Obtained: '已获得',
  Not_obtained: '未获得',
  Not_released: '未释放',
  Withdrawable_cash: '可提现',
  all: '全部',
  enter_withdrawal: '请输入提现金额.',
  enter_deposit_account: '请选择捐赠USDT保证金账户.',
  balance: '余额',
  explain: '说明',
  explainDesc1: '1.每提现1MEKE，您需要捐赠0.5USDT。',
  explainDesc2: '2.每次提现您需要支付10USDT的交易手续费。',
  donation: '捐赠',
  totalTransactions: '总交易U数量',
  AirdropNumber: '已获取MEKE',
  get_test: '获取MEKE公测物料',
  get_test_desc: 'MEKE是USDT本位的去中心化衍生品交易平台，参与公测需要获取测试用的USDT。',
  GetTestMaterials: '获取物料',
  test_desc1: '用测试U交易即可获取MEKE空投.',
  test_desc2: '每交易一笔U，将会得到对应数量的MEKE.',
  test_desc3: '最多可获取MEKE数量等于最初获取测试U数量.',
  audit: '审计',
  audit_desc: '安全是我们的首要任务',
  discord_desc: '与其他用户聊天',
  getMeke: '获取测试链BNB',
  getBNB: '获取BNB作为测试燃料费',
  publicTestUrl: '公测USDT合约地址：',
  InvitedFriends: '已邀请好友',
  day: '天',
  hour: '时',
  minute: '分',
  second: '秒',
  socketRetry: '网络异常， 正在重连...',

  CountdownStart: '开始倒计时',
  ActivityRunning: '火热进行中',
  CountdownEnd: '结束倒计时',
  ActivityFinished: '已结束',

  HowToGetAirdrop_0: '如何获得',
  HowToGetAirdrop_1: 'MEKE空投',
  HowToGetAirdrop_2: '?',

  GetItNow: '立即领取',
  More: '更多',
  MEKENews: 'MEKE新闻',

  airdropDetail: '参加公测， 赚取空投',
  airdrop: '空投',
  title: '标题',
  releaseRule: '释放规则',
  totalReleased: '总释放',
  totalAmount: '总额',
  collectedAmount: '累计领取',
  collectedPercent: '已领取 %',
  releasedPercent: '已释放 %',
  collect: '领取',
  MyAirdrops: '我的空投',
  collected: '已领取',
  SuccessToCollect: '领取成功',
  noAirdrops: '没有空投',

  Airdrop1: '空投一',
  Airdrop2: '空投二',
  CanGetUpToMEKE: '最多可获得MEKE',
  TradedUSDT: '已交易USDT',
  EarnedMEKE: '已获得MEKE',

  TradingPoints: '交易积分',
  InvitationPoints: '邀请链接注册积分',
  ReferralPoints: '推荐积分',
  ReferralAdditionalRewards: '推荐额外奖励',
  Goto: '前往',
  Swap: '交易',

  TotalAirdrop: '空投总数',
  airdrop2_desc1: '在完成空投1的基础上计算空投积分，每增加一个积分将额外增加空投1获取数量的1%。',
  airdrop2_desc2: '参与条件：获取的测试USDT≥100,000USDT',

  opBNBBridge: 'opBNB 跨链桥',
  opBNBBridge_title: '转账BNB到opBNB测试网',
  News: '新闻',
  PublicBetaTutorials: '公测教程',
  GetOpbnbGasFee: '领取opBNB链矿工费',
  GetOpbnbGasFeeDesc: 'MEKE目前运行在opBNB测试网上，在MEKE上交易需要领取opBNB中的BNB作为Gas费。',

  OpbnbGasFeeCollected: 'opBNB链矿工费已领取',
  Account: '账户',
  GetOpbnbTip: '链接钱包 领取opBNB链矿工费',
  AlreadyCollectedTip: '名额已使用',
  GetOpbnbNeedUsdtTip: '需币安链USDT≥0.1，仅用于排除机器人，无需支付',

  Total: '累计',
  Received: '已领取',
  Remain: '可领取',
  Released: '已释放',
  InvalidSignature: '无效签名',
  AirdropInsufficient: '已经全部领取',
  JoinMekeAirdrop: '加入MEKE空投',
  WMEKEAddress: 'WMEKE 合约地址',
  WithdrawalTutorial: '提现教程',
  Liquidation: '清算记录',
  LiquidateNotice: '清算通知',
  LiquidatedTip: '您的U本位账户保证金余额低于最低所需保证金，ETH/USDT仓位已被清算',
  UsdAmount: '金额',

  Profit: '盈利',
  Loss: '亏损',
  stopProfitSetting: '止盈',
  stopLossSetting: '止损',

  LimitOrder: '限价单',
  MarketOrder: '市价单',
  CloseOrderType: '订单类型',
  Cost: '成本',
  CostPrice: '成本价',
  StopProfitPrice: '止盈价',
  StopLossPrice: '止损价',
  Change: '涨跌',
  Size: '数量',
  Amount: '数量',

  Side: '持仓方向',
  LONG: '做多',
  SHORT: '做空',
  EMPTY: 'EMPTY',
  FLAT: 'FLAT',

  MyShare: '我的份额',
  MiningHistory: '挖矿历史记录',
  CurrentTermTradingFee: '本期支付的手续费',
  TradingFee: '支付的手续费',
  LiquidityScore: '流动性积分',
  EstimatedReward: '本期预估收益(MEKE)',
  TermReward: '收益(MEKE)',
  TotalReward: '总收益(MEKE)',
  CurrentProgress: '本期进度',
  TradingMiningDesc: '交易奖励： 一段时期内（28天）， 根据用户支付的手续费占本期总手续的比例发放奖励(MEKE)',
  LiquidityMiningDesc:
    '流通性奖励： 一段时期内（28天）， 根据用户提供的有效市场深度计算积分，根据用户积分占本期总积分的比例发放奖励(MEKE)',

  Earn: '赚利',
  Staking: '质押赚利',
  MyEarns: '我的收益',
  ReferralBonus: '邀请奖励',

  StakingMeke: '质押MEKE',
  StakingMekeDesc: '通过质押MEKE代币产生额外MEKE利息，及交易手续费分红',

  days: '天',
  APR: '年利率',
  StakeNow: '立即质押',
  Period: '周期',
  Balance: '余额',
  Interest: '利息',
  CompoundInterest: '自动复投（复利）',

  Special: '特惠',
  MekeSpecialPackage: 'MEKE优惠套餐',
  MekeSpecialPackageDesc:
    '除了空投质押外，用户质押MEKE需要购买MEKE后才能质押。用户可以在opBNB 上的uniswap上购买MEKE，也可以官网折扣购买。',
  BuyItNow: '立即购买',
  BuyAndStakeDesc1: '购买后自动转入',
  BuyAndStakeDesc2: '天质押池',

  MekeStakingRule: 'MEKE质押生息规则',
  MekeStakingRuleDesc1: '分别创建5天，30天，90天，180天为周期的四种质押生息方式。',
  MekeStakingRuleDesc2: '利率是根据MEKE计算的。',
  MekeStakingRuleDesc3: '不同周期的年利率不同，180天质押周期的年利率为50%，90天为35%，30天为20%，5天为5%。',
  MekeStakingRuleDesc4:
    '注意，当用户质押MEKE后立刻产生收益，如果自动复投，收益是按照秒进行复利计算。年利率是按每秒复利计算的。',
  MekeStakingRuleDesc5:
    '以180天质押为周期，年利率50%为例。该50%是通过每秒复投产生的复利计算一年的总利息，再用总利息除以初始本金得到的年化利率。',
  MekeStakingRuleDesc6: '领取质押收益，会销毁领取利息的5%。 例如，领取100MEKE利息，实际到账95MEKE, 销毁5MEKE。',

  StakingReleaseRule: '质押释放规则',
  StakingReleaseRuleDesc1:
    '质押后，按照质押的周期数平均每秒释放。假如质押5天，则5天结束后，本金和利息按照按照5天为周期，每秒平均释放。释放后的MEKE可提取',
  StakingReleaseRuleDesc2:
    '释放期间，只要释放的MEKE未提取，依旧可以产生利息。利息依旧可以复投。释放结束后，将不再产生利息。',

  FeeDividendRule: '交易手续费分红规则',
  FeeDividendRuleDesc1: '除质押生息MEKE外，质押MEKE还可以分整个交易手续费的60%。',
  FeeDividendRuleDesc2:
    '具体分配方式为每交易一笔，按照当前所有质押的MEKE数量平均分配。手续费为USDT，需要用户自己花费GAS提取。',
  FeeDividendRuleDesc3:
    '除了分配的手续费外，项目方还将每10天手动给用户补贴已经分得手续费得一定百分比。例如，用户10天分到了20U手续费，项目放可以再补贴20%,即再补贴4U手续费。此数字为项目方手动操作，具体补贴时间和补贴数量，以项目方为准。',

  StakingEarns: '质押收益',
  Principal: '质押本金',
  TotalReleased: '累计释放',
  MyShares: '我的份额',
  TotalInterest: '累计收益',
  WithdrawEarns: '提取收益',
  WithdrawFees: '提取手续费',
  Available: '可提取',
  Withdrawn: '已经提取',
  TotalTransactionFee: '累计手续费',

  StakingRecords: '质押记录',

  C_StartTime: '开始时间(UTC)',
  C_Period: '周期',
  C_APR: '年利率(%)',
  C_Principal: '本金(MEKE)',
  C_Interest: '收益(MEKE)',
  C_Released: '已释放(%)',
  C_Available: '可领取(MEKE)',

  TransactionFeeExtraRewards: '手续费额外补贴',
  D_Time: '补贴时间(UTC)',
  D_Amount: '补贴数量(USDT)',

  InviteFriends: '邀请好友',
  InviteFriendsDesc: '邀请好友 享额外奖励',
  Copy: '复制',

  InvitationRule: '邀请规则',
  InvitationRuleDesc1: '被邀请的人，通过别人邀请链接注册，且必须满足激活VIP，才算邀请成功。',
  InvitationRuleDesc2:
    'A每邀请一个用户B，额外奖励A被邀请者B质押MEKE产生利息的10%。即如果被邀请者产生了100MEKE利息，邀请者A将获得额外奖励10% ,就是10MEKE。',
  InvitationRuleDesc3: '领取邀请奖励，会销毁领取的5%。 例如，领取100MEKE，实际到账95MEKE, 销毁5MEKE。',

  TotalReferralBonus: '累计邀请奖励',
  WithdrawBonus: '领取奖励',

  ReferralBonus: '推荐奖励',
  E_Addr: '地址',
  E_Bonus: '推荐奖励',

  F_ActivationTime: '激活时间(UTC)'
}
export default lang;