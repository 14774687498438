import request from '@/utils/request';
import store from '@/store'
import WalletUtil from '@/utils/walletUtil'


export async function getMkConfig(){
  if(!window.mkConfig){
    window.mkConfig = await getMekeConfig();

    store.commit('setAppConfig', window.mkConfig.data)
    store.commit("setCurrencyConfig",window.mkConfig.data)

    _vm.$eventBus.$emit('mekeConfig_msg', window.mkConfig)
    _vm.$eventBus.$emit('config_msg', window.mkConfig)
  }
  return window.mkConfig;
}

export async function getPairConfig(pairAddress){
  const mkConfig=await getMkConfig();
  const pair= (mkConfig.data.pairs || []).find((pair) => pairAddress.toLowerCase() == pair.address.toLowerCase());
  return pair;
}

export async function getCurrencyList(){
  const mkConfig = await getMkConfig();
  return [{
    type:'USDT',
    address: mkConfig.data.usdt
  }]
}

export async function getMekeConfig() {
  return request({
    url: '/config',
    method: 'get'
  }).then((res) => {
    res.data.blockChainAddr = res.data.rpcUrls[0]
    res.data.contractreader = res.data.contractReader
    res.data.usdc = res.data.usdt
    res.data.gasFee=res.data.gasFee || 0.1;
    res.data.realTakerFeeRate = res.data.takerFeeRate||1000
    res.data.realMakerFeeRate = res.data.makerFeeRate||500
    res.data.perpetual = res.data.pairs[0].perpetual;

    const {pairs, ...appConfig}=res.data;
    const mergedPairs=pairs.map(pair=>({
      ...appConfig,
      ...pair,
      img:pair.iconUrl,
      abbreviation:pair.shortName,
      fundmork: pair.fundingConfig.address,
      contractreader: res.data.contractReader,
    }));
    window.mkConfig = { data: { ...(mergedPairs[0]||{}), ...appConfig, pairs: mergedPairs}};
    localStorage.setItem("appConfig", JSON.stringify(mkConfig.data));
    return window.mkConfig
  })
}

let getPairsTimeoutId=0;
let pairsData=null;

export async function getPairs(){
  clearTimeout(getPairsTimeoutId)
  getPairsTimeoutId=setTimeout(()=>loadPairs(),5000);
  if (pairsData == null) {
    pairsData={};
    pairsData = await loadPairs()
  }

  if(pairsData.data){
      _vm.$eventBus.$emit('tradePair_msg', pairsData)
      _vm.$eventBus.$emit('handicapInformation_msg', pairsData)
      _vm.$eventBus.$emit('position_msg', pairsData)
  }

  return pairsData;
}

export async function loadPairs() {
  return request({
    url: '/pairs',
    method: 'get'
  }).then(async (res) => {
    for(let i=0;i<res.data.length;i++){
      var pairConfig=await getPairConfig(res.data[i].address);
      res.data[i] = {...pairConfig, ...res.data[i]};
    }
    res.data.forEach(pair=>{
      pair.percentage = pair.pricePercent
      pair.change = pair.pricePercent > 0 ? 1 : (pair.pricePercent < 0 ? 0 : 2)
      pair.price = pair.fairPrice
      pair.totalTurnover = pair.totalAmount
      pair.fundingRate = pair.fundingData.fundingRate;
    });
    pairsData=res;
    return res
  })
}

//当前项目

/**
 * 下单
 * 参数 type：subLimit 下现价单,subMarket 下市价单,cancelOrder 取消订单 
   参数：side（buy/sell） ,orderID(订单哈希) ,trader(用户地址), amount（买/卖数量）,price（买/卖价格）
   参数：side（buy/sell） ,orderID(订单哈希) ,trader(用户地址), amount（买/卖数量）
   参数：orderID（订单哈希）
 */
export async function subOrder(params) {
  const {trader, broker, perpetual, amount, price, data, orderHash:hash, signRes:signature, type, keepMaxPrice, keepMinPrice} = params;
  const newParams = {
    trader,
    broker,
    perpetual,
    hash,
    signature,
    data,
    type,
    amountPPB: BigInt(amount) / BigInt(1E9) + '',
    pricePPB: BigInt(price) / BigInt(1E9) + '',
    keepMinPricePPB: BigInt(Math.round((keepMinPrice || 0) * 1E9))+'',
    keepMaxPricePPB: BigInt(Math.round((keepMaxPrice || 0) * 1E9))+''
  }

  const account=trader;


  return request({
    url: `/order/user/${account}/create`,
    method: 'post',
    params: newParams
  })
}

export async function getTraderFills(account, perpetual, params){
  return request({
    url: `/order/user/${account}/fills/${perpetual}`,
    method: 'get',
    params
  })
}

//获取全部订单
export async function getAllOrder(account, {perpetual, ...params}) {
    return getTraderFills(account, perpetual, params).then((res) => {
      res.data.records.forEach((item) => {
        item.timeStr = new Date(item.blockTime*1000).toISOString();
        item.status = 1;
      })
      return res
    })
}
//获取订单
export async function getOrder(account, perpetual) {
  return getAvailableOrders(account, perpetual).then((res) => {
    res.data.forEach((item) => (item.expireStr = new Date(item.expiredAt * 1000).toISOString()))
    return res
  })
}
//撤销订单
export async function cancelOrder(account,params) {
    return request({
      url: `/order/user/${account}/cancel`,
      method: 'post',
      params
    })
}
//获取邀请码
export async function getInvitation(params) {
    return request({
        url: '/invitation/create',
        method: 'get',
        params
    });
}
//根据用户邀请码获取用户地址
export async function getAddressByInvitation(params) {
    return request({
        url: '/invitation/address',
        method: 'get',
        params
    });
}

//创建上下级
export async function createRelation(params) {
    return request({
      url: '/relation/create',
      method: 'post',
      params
    })
}

//确认上下级关系
export async function defineRelation(params) {
    return request({
      url: '/relation/define',
      method: 'post',
      params
    })
}

//查询当前用户是否拥有上下级关系
export async function getRelation(params) {
    return request({
      url: '/relation/getrelation',
      method: 'get',
      params
    })
}

//发送消息
export async function sendMsg(params) {
    if (params?.cmd && !params?.perpetual && store.state.blockchain.perpetual) {
      params.perpetual = store.state.blockchain.perpetual;
    }
    console.warn('<< send msg', params, (performance.now()/1000).toFixed(3)+'s');

    if (params.cmd == 'handicap') {
        return getOrderBook(params.perpetual);
    }else if(params.cmd == 'deals'){
      return getLatestDeals(params.perpetual);
    }else if(params.cmd == "tradingview"){
      return getKline(params.perpetual, params.data);
    }else if (params.cmd == 'fundingRate') {
      return getFundingLine(params.perpetual, params.data)
    } else if (params.cmd == 'config') {
      return getMkConfig()
    } else if (params.cmd == 'tradePair') {
      return getPairs()
    }else if (params.cmd == 'position') {
      return getPairs()
    } else if (params.cmd == 'handicapInformation') {
      return getPairs()
    } else if (params.cmd == 'login' && params.data) {
      return userLogin(params.data)
    } else if (params.cmd == 'logout') {
      userLogout()
        .then(() => {
          console.log('user logout')
        })
        .catch((err) => {
          console.warn('logout failed: ' + err.message)
        })
      localStorage.removeItem('xAuthToken')
    }
    return {};
}

let sendTradePairMsgTimeoutId=0
export async function sendTradePairMsg(){
    clearTimeout(sendTradePairMsgTimeoutId)
    sendTradePairMsgTimeoutId=setTimeout(()=>sendMsg({cmd:'tradePair'}),100)
}

export async function getOrderBook(pepetual){
    return request({
      url: `/order/orderbook/${pepetual}`,
      method: 'get'
    })
      .then((res) => {
        var depthData = {
          buy: [...res.data.buy].sort((a, b) => a.p - b.p),
          sell: [...res.data.sell].sort((a, b) => a.p - b.p)
        }

        res.data.buy = res.data.buy.sort((a, b) => b.p - a.p).slice(0, 30)
        res.data.sell = res.data.sell.sort((a, b) => b.p - a.p).slice(-30)
        res.data.myOrder=[];
        _vm.$eventBus.$emit('handicap_msg', res)
        _vm.$eventBus.$emit('depthchart_msg',{...res, data : depthData})
        return res;
      })
      .catch((err) => console.warn(err.message))
}

export async function getLatestDeals(pepetual){
    return request({
      url: `/order/deals/${pepetual}`,
      method: 'get'
    })
      .then((res) => {
        if(res?.data?.list?.length){
          res.data.list.forEach(item=>{
            item.time=item.blockTime*1000;
            item.amount = item.volume;
            item.side = item.side=='LONG'||item.side=='buy' ? 'buy':'sell';
          });
        }
        _vm.$eventBus.$emit('deals_msg', res)
        return res;
      })
}

export async function getKline(pepetual, viewPeriod, fromTime){
  const periodMap={'1':'m1', '15':'m15', '60':'m60','1D':'d1'}
  const period = periodMap[viewPeriod];
  if(period==null){
    throw new Error(`unsupport period ${viewPeriod}`);
  }
  return request({
    url: `/kline/${pepetual}/${period}`,
    method: 'get',
    params: fromTime > 0 ? { from: fromTime } : {}
  }).then((res) => {
    if (res?.data?.data?.length > 0) {
      res.data.lastTime = res.data.data.at(-1).t
      if (fromTime > 0) {
        res.data.type = 'add'
      } else {
        res.data.type = 'get'
      }
      res.data.real = res.data.data.at(-1)
    }
    _vm.$eventBus.$emit('tradingview_msg', res)
    return res
  })
}

export async function getFundingLine(pepetual, fromTime) {
  return request({
    url: `/fundingline/${pepetual}`,
    method: 'get',
    params: fromTime > 0 ? { from: fromTime } : {}
  }).then((res) => {
    if (res?.data?.data?.length > 0) {
      res.data.lastTime = res.data.data.at(-1).t
      if (fromTime > 0) {
        res.data.type = 'add'
      } else {
        res.data.type = 'get'
      }
      res.data.real = res.data.data.at(-1)
      _vm.$eventBus.$emit('fundingRate_msg', res)
    }
    return res
  })
}

export async function getAvailableOrders(account, pepetual) {
  return request({
    url: `/order/user/${account}/availables/${pepetual}`,
    method: 'get'
  })
}

//获取交易费率
export async function userFee(account) {
  return request({
    url: `/user/${account}/fee`,
    method: 'get'
  }).then(res=>{
    if(res.data){
      var oldState = store.state.app;
      store.commit('setFeeRate', {
        ...oldState,
        realTakerFeeRate: res.data.takerFeeRate,
        realMakerFeeRate: res.data.makerFeeRate,
        takerFeeRate: res.data.takerFeeRate
      })
    }
    return res;
  })
}

//获取通知消息
export async function getMessageAll(account,params) {
    return request({
      url: `/user/${account}/message/all`,
      method: 'get',
      params
    })
}

export async function getMessageUnreaded(account, params) {
  return request({
    url: `/user/${account}/message/unreaded`,
    method: 'get',
    params
  })
}

export async function setMessageReaded(account, messageId) {
  return request({
    url: `/user/${account}/message/read`,
    method: 'post',
    params: {messageId}
  })
}



export async function getLiquidation(account, params) {
  return request({
    url: `/user/${account}/liquidations`,
    method: 'get',
    params
  })
}


export async function getWalletMargin(account,perpetual) {
    return request({
      url: `/user/${account}/pair/${perpetual}`,
      method: 'get'
    })
}


//所有邀请人
export async function relation_lower(params) {
    return request({
      url: `/relation/lower/${params.address}`,
      method: 'get'
    })
}
//倒计时
export async function close_get(params) {
    return request({
      baseURL: '/test/api/v1',
      url: `/close/get`,
      method: 'get',
      params
    })
}


export async function collectOpbnb(address) {
  return request({
    baseURL: '/test/api/v1',
    url: `/user/fuel/get?address=${address}`,
    method: 'get'
  })
}

export async function isOpbnbCollected(address) {
  return request({
    baseURL: '/test/api/v1',
    url: `/user/fuel/record?address=${address}`,
    method: 'get'
  })
}


export async function getAirdropDetail(account) {
  return request({
    url: `/user/${account}/airdrop/detail`,
    method: 'get',
  })
}

export async function getAirdropSign(account, activityId) {
  return request({
    url: `/user/${account}/airdrop/sign?activityId=${activityId}`,
    method: 'get'
  })
}


export async function getMiningSign(account) {
  return request({
    url: `/user/${account}/mining/sign`,
    method: 'get'
  })
}

export async function getMiningDetail(account) {
  return request({
    url: `/user/${account}/mining/detail`,
    method: 'get'
  })
}

async function getLoginParams(addr) {
    const address = addr.toLowerCase()
    try{
        const params=JSON.parse(localStorage.getItem(address)||'{}');
        if(params.address === addr && params.signature.length===132 && (params.timestamp + 3600*24)*1000 > Date.now()){
            return params;
        }
    }catch(err){
        //ignore;
    }

    const timestamp=Date.now()/1000|0;
    const message = `${address} login at ${new Date(timestamp*1000).toISOString().substring(0, 19) + 'Z'}`
    const signature = await WalletUtil.web3.eth.personal.sign(message, address)

    const params={
        address,
        signature,
        timestamp,
    }
    localStorage.setItem(address,JSON.stringify(params));
    return params;
}

export async function userLogin(address) {
    const params=await getLoginParams(address);
    return request({
      url: `/user/login`,
      method: 'post',
      params
    }).then((res) => {
      localStorage.setItem('xAuthToken', res.data.token)
      store.commit('login', { isInit: true, address })
      return res;
    })
}

export async function userLogout() {
  return request({
    url: `/user/logout`,
    method: 'post',
  })
}

export async function getEarnConfig(chainId){
      var mkConfig=await getMkConfig();
      return {
        earn: mkConfig.data.earn,
        earnReader: mkConfig.data.earnReader,
      }
}

export async function getActiveReferrees(address){
    return await relation_lower({address}).then((res) => res.data || [])
}