/**
 * 系统信息
 */
export default {
    namespaced: true,
    state: {
        isUnread:false,//是否未读
    },
    getters: {
        getIsUnread(state) {
            return state.isUnread;
        },
    },
    mutations: {
        setIsUnread(state,val){
            state.isUnread=val;
        }
    },
    actions: {}
};
